<template>
    <div class="main-block request-management">
        <div class="div col-12 p-0 d-flex align-items-start">
            <h4 class="">{{ $t('title.events') }}</h4>
        </div>
        <div class="scrollable-block">
            <div class="col-12 row mt-3 m-0 p-0" style="min-height: 90%">
                <crud-table :columns="columns" :list-url="listUrl" ref="application-table" v-on:refresh="resetFilter">
                    <template slot>
                        <router-link :to="{name:'adminEventForm',params:{operation:'add'}}" class="btn btn-primary">
                            <b-icon icon="plus" class="icon-style"></b-icon>
                            {{ $t('title.addNew') }}
                        </router-link>
                    </template>
                    <template #cell(manager)="{item}">
                        {{ ( item.manager || {} ).full_name }}
                    </template>
                    <template #cell(last_stopped_at)="{item}">
                        {{ item.last_stopped_at ? $global.utcDateToLocalDate(item.last_stopped_at) : null }}
                    </template>
                    <template #cell(created_at)="{item}">
                        {{ item.created_at ? $global.utcDateToLocalDate(item.created_at) : null }}
                    </template>
                    <template #cell(status)="{item}">
                        <span class="text-success" v-if="item.status == true">{{ $t('title.active') }}</span>
                        <span v-else class="text-danger">{{ $t('title.deActive') }}</span>
                    </template>
                    <template #cell(actions)="{item}">
                        <router-link :to="{name:'adminEventMap',params:{id:item.id}}"
                                     v-if="item.status"
                                     variant="outline-primary"
                                     class="mr-1" @click="onSubmit(item);">
                            <b-icon icon="map" class="icon-style"></b-icon>
                        </router-link>
                        <router-link :to="{name:'adminEventForm',params:{id:item.id,operation:'edit'}}"
                                     v-if="item.status"
                                     variant="outline-primary"
                                     class="mr-1" @click="onSubmit(item);">
                            <b-icon icon="pencil-square" class="icon-style"></b-icon>
                        </router-link>
                    </template>
                </crud-table>
            </div>
        </div>
    </div>
</template>

<script>

import { request } from "@/Util/Request";

const FILTER_FORM = {
    roles: null,
    statuses: null
};

const COLUMN_STATE = (self) => [
    { key: 'actions', label: self.$t('title.actions') },
    { key: 'status', label: self.$t('title.status'), sortable: true },
    { key: 'name', label: self.$t('title.name'), sortable: true },
    { key: 'manager', label: self.$t('title.manager'), sortable: true },
    { key: 'address', label: self.$t('title.address'), sortable: true },
    { key: 'system_note', label: self.$t('title.systemNotes'), sortable: true },
    { key: 'last_stopped_at', label: self.$t('title.lastStoppedAt'), sortable: true },
    { key: 'created_at', label: self.$t('title.createdAt'), sortable: true },
];

export default {
    data() {
        return {
            operation: '',
            listUrl: '/admin/events',
            filters: {
                ...FILTER_FORM
            },
        };
    },
    methods: {
        async onSubmit(item) {
            try {
                const response = await request({
                    method: 'patch',
                    url: `/admin/application-users/${ item.id }`,
                    data: {
                        'is_approved': 1
                    }
                });

                this.$bvToast.toast(`Accept request successfully`, {
                    title: `Accept Request`,
                    solid: true,
                    variant: 'success',
                });

                this.$refs['application-table'].refreshTableData();

            } catch (error) {
                this.notifyError();
            }

        },
        handleFilter() {
            this.$refs['application-table'].handleFilter(this.filters);
        },
        resetFilter() {
            this.filters = { ...FILTER_FORM };
        },
    },
    computed: {
        columns() {
            return COLUMN_STATE(this);
        }
    }
};
</script>
