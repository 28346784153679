<template>
    <div class="w-100 d-flex mt-3">
        <div class="col-12">
            <b-form @submit.prevent="handleSubmit">
                <b-row>
                    <div class="col-12 col-md-8">
                        <b-row>
                            <div class="col-12 col-md-6">
                                <label for="name">{{ $t('title.name') }}</label>
                                <b-form-input @input="resetError" name="name" v-model="formFields.name"
                                              type="text"></b-form-input>
                                <b-form-invalid-feedback
                                    :state="!formErrors.has('name')">
                                    {{ formErrors.first('name') }}
                                </b-form-invalid-feedback>
                            </div>
                        </b-row>
                        <b-row>
                            <div class="col-12">
                                <hr class="hr-basic mb-0" />
                            </div>
                            <div class="col-12">
                                <div
                                    class="mb-5 mt-2 gap-3 justify-content-start align-items-center px-2 px-lg-0 flex-column flex-md-row"
                                >
                                    <button :disabled="loader" type="submit"
                                            class="btn main-btn fw-semibold col col-lg-auto mw-220">
                                        <span>{{ $t('title.saveAndClose') }}</span>
                                    </button>
                                </div>
                            </div>
                        </b-row>
                    </div>
                </b-row>
            </b-form>
        </div>
    </div>
</template>

<script>
import Error from "@/Util/Error";
import { mapState } from "vuex";
import destinationMixin from "@/Util/destinationMixin";
import { request } from "@/Util/Request";

const DEFAULT_FORM = {
    id: null,
    name: null
};

export default {
    mixins: [destinationMixin],
    data() {
        return {
            formFields: { ...DEFAULT_FORM },
            formErrors: new Error({}),
            dropdowns: {
                languages: []
            }
        };
    },
    mounted() {
        if ( this.$route.params && this.$route.params.id ) {
            this.getDetail(this.$route.params.id);
        }
    },
    methods: {
        async handleSubmit() {
            this.resetError();
            try {

                const response = await request({
                    method: 'put',
                    url: `/admin/categories/${ this.formFields.id }`,
                    data: this.formFields
                });

                this.notifySuccessWithMsg(`${ this.$t('title.category') } ${ this.$t('title.updateSuccessfully') }`);

                this.$router.push({ name: 'adminCategory' });

            } catch (error) {
                if ( error.request && error.request.status && error.request.status === 422 ) {
                    this.formErrors = new Error(JSON.parse(error.request.responseText).errors);
                    return false;
                } else {
                    this.notifyError();
                }
            }
        },
        async getDetail(id) {
            try {
                const response = await request({
                    method: 'get',
                    url: `/admin/categories/${ id }`
                });

                const { data } = response;

                this.formFields = {
                    id: data.id,
                    name: data.name
                };

            } catch (error) {
                this.notifyError();
            }
        },
        resetError() {
            this.formErrors = new Error({});
        }
    },
    computed: {
        ...mapState(['loader'])
    }
};
</script>
