<template>
    <div>
        <b-modal v-model="isOpenModal" id="userDeleteModal"  :hide-header="true"
                 :hide-footer="true">
            <div class="col-12" v-if="roles.includes('user')">
                <h5>{{$t('title.role')}} <b>{{$t('title.user')}}</b></h5>
                <p v-if="statusCounts.length == 0">{{$t('title.noApplicationFound')}}</p>
                <p v-else><b>Following table is display user existing applications status</b></p>
                <table class="table" v-if="statusCounts.length > 0">
                    <thead>
                    <tr>
                        <th>{{ $t('title.applicationStatus') }}</th>
                        <th>{{ $t('title.count') }}</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="status in statusCounts" :key="status.status">
                        <td>{{ status.status }}</td>
                        <td>{{ status.count }}</td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <div class="col-12" v-if="roles.includes('volunteer')">
                <h5>{{$t('title.role')}} <b>{{$t('title.volunteer')}}</b></h5>
                <p><b>{{$t('title.volunteerInTreatment')}} : {{totalIntreatmentRequest}}</b></p>
                <div class="mb-3" v-if="totalIntreatmentRequest > 0">
                    <p>{{$t('title.openVolunteerList')}}</p>
                    <label for="type">Select Volunteer</label>
                    <treeselect :multiple="false" v-model="formFields.volunteer_id" :options="volunteers"
                                :placeholder="$t('title.select')"
                                id="type"></treeselect>
                    <b-form-invalid-feedback
                        :state="!formErrors.has('volunteer_id')">
                        {{ formErrors.first('volunteer_id') }}
                    </b-form-invalid-feedback>
                </div>
            </div>
            <div class="col-12">
                <p>{{$t('title.deleteUserMessage')}}</p>
            </div>
            <div class="modal-footer d-flex mr-2 gap-2">
                <button type="button" v-if="totalIntreatmentRequest == 0" class="btn main-btn col m-0" @click="handleSubmitRequest">
                    {{ $t('title.yes') }}
                </button>
                <button type="button" v-else :disabled="(formFields.volunteer_id) ? false : true" class="btn main-btn col m-0" @click="handleSubmitRequest">
                    {{ $t('title.yes') }}
                </button>
                <button type="button" class="btn sec-btn col m-0" @click="cancelModal">
                    {{ $t('title.cancel') }}
                </button>
            </div>
        </b-modal>
    </div>
</template>

<script>
import {request} from "@/Util/Request";
import Error from "@/Util/Error";

const FORM_STATE = {
    volunteer_id: null
};

export default {
    data() {
        return {
            isOpenModal: false,
            statusCounts : [],
            userId: null,
            formErrors: new Error({}),
            roles: [],
            volunteers: [],
            totalIntreatmentRequest: 0,
            formFields: { ...FORM_STATE },
        };
    },
    methods: {
        async handleToggleModal(id, roles) {
            this.refreshModal();
            this.userId = id;
            this.roles = roles.map((role) => role.name);
            if(this.roles.includes('user')) {
                try {
                    const response = await request({
                        method: 'post',
                        url: `admin/users/applications/${ id }`,

                    });

                    let { data } = response;
                    this.statusCounts = data;
                } catch (error) {
                    this.notifyError();
                }
            }

            if(this.roles.includes('volunteer')) {
                try {
                    const response = await request({
                        method: 'post',
                        url: `admin/volunteer/intreatment/${ id }`,

                    });

                    let { data } = response;
                    this.totalIntreatmentRequest = data.intreatment_request;
                    if(this.totalIntreatmentRequest > 0) {
                        this.getOpenVolunteerLists(id);
                    }
                }  catch (error) {
                    this.notifyError();
                }
            }

            this.isOpenModal = !this.isOpenModal;
        },
        async getOpenVolunteerLists(id) {
            try {
                const response = await request({
                    method: 'post',
                    url: `admin/volunteer/lists/${ id }`,

                });

                let { data } = response;
                this.volunteers = data;
            }  catch (error) {
                this.notifyError();
            }
        },
        async handleSubmitRequest() {
            try {
                const response = await request({
                    method: 'delete',
                    url: `/admin/users/${ this.userId }`,
                    data: this.formFields
                });
                this.notifySuccessWithMsg(`User ${ this.$t('title.deletedSuccessMsg') }`);
                this.$emit('refreshTable');
                this.cancelModal();
            } catch (error) {
                if ( error.request && error.request.status && error.request.status === 422 ) {
                    this.formErrors = new Error(JSON.parse(error.request.responseText).errors);
                    return false;
                }
            }

        },
        cancelModal() {
            this.isOpenModal = false;
        },
        refreshModal() {
            this.totalIntreatmentRequest = 0;
            this.statusCounts = [];
            this.formErrors = new Error({});
            this.formFields = { ...FORM_STATE };
        }
    }
};
</script>
