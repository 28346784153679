<template>
    <div>
        <div v-if="locale == 'he-IL'">
            <div class="main-block about-us">
                <div class="div col-12 p-0 d-flex align-items-start">
                    <h4 class="">אודותינו</h4>
                </div>
                <div class="scrollable-block">
                    <b-container fluid class="p-0 overflow-hidden">
                        <div class="col-12 row mt-3 m-0 p-0">
                            <p>
                                משימת האפודה הסגולה היא יוזמה לקידום מוכנות לנגישות במצבי חירום עבור אנשים עם מוגבלות
                                וקשישים. משימת האפודה הסגולה הושקה במרץ 2022 על ידי "נגישות ישראל", הארגון המוביל בישראל
                                לקידום נגישות ושילוב של כל סוגי המוגבלות בכל תחומי החיים. בתור מובילי תחום הנגישות
                                והשילוב בישראל, "נגישות ישראל" פועלת לעיצוב מוכנות ישראל במצבי חירום על מנת לוודא שאנשים
                                עם מוגבלות וקשישים לא יישארו מאחור. "נגישות ישראל" הובילה שולחנות עגולים עם בעלי עניין
                                כדי להבין את האתגרים, הצרכים והפתרונות האפשריים, קיימה הדרכות לבעלי עניין שונים בנושא
                                מוכנות לזמני חירום, והייתה חלק מתהליך חקיקה מתקדם שהוביל לתקנות בנושא מוכנות במצבי
                                חירום.
                            </p>
                            <p>
                                כאשר פרצה המלחמה באוקראינה, "נגישות ישראל" הבינה את הצורך להתמקד במציאת פתרונות עבור
                                אלו הפגיעים ביותר – הקשישים ובעלי המוגבלות. בקשות לעזרה החלו להגיע, עם צרכים ייחודיים
                                לאנשים עם מוגבלות. "נגישות ישראל" הבינה מיד כי אנשים עם מוגבלות וקשישים נזנחים ולא
                                מפונים בשל המורכבות של תיאום פינוי נגיש והיעדר מידע זמין בנושא זה.
                            </p>
                            <p>
                                "נגישות ישראל" החליטה מיד להשיק את "<strong>משימת האפודה הסגולה</strong>" – המטרה
                                הכוללת שלנו לטווח הארוך היא ליצור תוכניות חירום לאנשים עם מוגבלות ולקשישים ברחבי העולם,
                                על מנת להבטיח מוכנות ותוכנית לזמני חירום. <strong>משימת האפודה הסגולה</strong> מתמקדת
                                באנשים עם מוגבלות, קשישים ומשפחותיהם.
                            </p>
                            <h3>למה אפודה סגולה?</h3>
                            <p>
                                הרעיון מאחורי האפודה הסגולה הוא שכאשר אנשים עם מוגבלות וקשישים רואים אדם שלובש אפודה
                                סגולה (עם הצבע הסגול המזוהה עם זכויות לאנשים עם מוגבלות), הם ידעו כי אותו אדם הוכשר ויש
                                לו את הכלים לספק עזרה בצורה נגישה.
                            </p>
                            <div class="row">
                                <div class="col-12 col-md-6">
                                    <div class="elementor-widget-container">
                                        <p>
                                            באפריל 2022 קיבלנו תרומה נדיבה מקרן ניפון ביפן ותורמים נוספים, שאיפשרה לנו
                                            לממן
                                            פינויים נגישים מורכבים, לספק ציוד, קשרים ומידע נוסף עבור אנשים עם מוגבלות
                                            וקשישים
                                            באוקראינה ולהעלות מודעות ולקיים הדרכות גם מקוונות וגם פנים מול פנים למתנדבים
                                            ולארגונים הפועלים באוקראינה וסביבתה כדי לספק כלים להפוך את המאמצים שהם
                                            מספקים בזמן
                                            הקשה הזה לנגישים ומתאימים לאנשים עם מוגבלות ולקשישים.
                                        </p>
                                        <p>
                                            בדצמבר 2022 קיבלנו תמיכה ממשרד החוץ בישראל ותמיכה מתמשכת מ-"Accessibility
                                            Accelerator"
                                            מארה"ב, שאיפשרו לנו להמשיך במאמצים לוודא שאנשים עם מוגבלות וקשישים נחשבים
                                            ומהווים
                                            חלק מההכנה למצבי חירום, תוך התמקדות במודעות, מידע, הדרכה וחיבור בין הגורמים
                                            הפועלים בשטח כדי לוודא
                                        </p>
                                    </div>
                                </div>
                                <div class="col-12 col-md-6">
                                    <img
                                        src="https://purple-vest.net/wp-content/uploads/2022/05/b8b9e6f6-0cd1-4a24-b994-1061cca73bfb-copy.png"
                                        alt=""
                                        class="w-100"
                                    />
                                </div>
                            </div>
                        </div>
                    </b-container>
                </div>
            </div>
        </div>
        <div v-else class="main-block about-us">
            <div class="div col-12 p-0 d-flex align-items-start">
                <h4 class="">About Us</h4>
            </div>
            <div class="scrollable-block">
                <b-container fluid class="p-0 overflow-hidden">
                    <div class="col-12 row mt-3 m-0 p-0">
                        <p>
                            The Purple Vest Mission is an initiative which promotes accessibility preparedness in
                            times of emergency for people with disabilities and the elderly. The Purple Vest Mission
                            was initiated on March 2022 by Access Israel who is the leading organization in Israel
                            promoting accessibility and inclusion for all types of disabilities in all areas of
                            life. As leaders of accessibility and inclusion in Israel, Access Israel is very
                            experienced and active in shaping the preparedness of Israel in times of emergency
                            making sure people with disabilities and elderly are not left behind. Access Israel led
                            round tables with stakeholders to understand challenges, needs and possible solutions
                            and conducted training various stakeholders for preparedness in time of emergency and
                            with being part of the advanced legislation process resulting in regulations on
                            preparedness in times of emergency.&nbsp;
                        </p>
                        <p>
                            When the war broke in Ukraine, Access Israel understood the need to focus on making sure
                            solutions on the ground fit those most vulnerable – the elderly and those with
                            disabilities. The requests for help started to come, calling for help for specific and
                            unique needs for people with disabilities. Access Israel immediately understood that
                            people with disabilities and the elderly are left behind and are not evacuated because
                            the complexity of arranging accessible evacuation and because of lack of available
                            information on this subject.
                        </p>
                        <p>
                            Access Israel immediately decided to initiate the “<strong>Purple Vest Mission</strong>”
                            – Our overall long-term goal is to create contingency plans for people with disabilities
                            and the elderly globally, to ensure preparedness and a plan for times of emergency.
                            <strong>Purple Vest mission</strong> is focused on people with disabilities and the
                            elderly and their families.&nbsp;
                        </p>
                        <h3>Why Purple Vest?</h3>
                        <p>
                            The idea behind the purple vest itself is that when people with disabilities and the
                            elderly see a person wearing a purple vest (with the color purple which is identified
                            with rights for people with disabilities) they will know that this person was trained
                            and has the tools to provide assistance in an accessible way.
                        </p>
                        <div class="row">
                            <div class="col-12 col-md-6">
                                <div class="elementor-widget-container">
                                    <p>
                                        In April 2022, we received a generous donation from The Nippon Foundation from
                                        Japan and other donors, enabling us to provide and fund accessible complex
                                        evacuations, provide supplies, contacts and other information for people with
                                        disabilities and elderly in Ukraine and raise awareness and conduct trainings
                                        both
                                        virtual and face-to-face to volunteers and organizations operating in and around
                                        Ukraine to provide tools on making the efforts they provide during these
                                        difficult
                                        times more accessible and fitting for people with disabilities and the elderly.
                                    </p>
                                    <p>
                                        In December 2022 we received the support from the Ministry of Foreign Affairs in
                                        israel and continuous support of Accessibility Accelerator from the USA enabling
                                        us to continue the efforts of making sure people with disabilities and elderly
                                        are
                                        considered and part of preparing for times of emergency, focusing on awareness,
                                        information, training and continuing to connect the dots between those operating
                                        on the ground making sure people with disabilities and elderly are not left
                                        behind.
                                    </p>
                                    <p>
                                        <strong>&nbsp;</strong
                                        ><strong
                                    >The Purple Vest Mission listens to needs on the ground, identifies all
                                        available resources, and connects the dots to facilitate improved experiences
                                        for people with disabilities.</strong
                                    >
                                    </p>
                                </div>
                            </div>
                            <div class="col-12 col-md-6">
                                <img
                                    src="https://purple-vest.net/wp-content/uploads/2022/05/b8b9e6f6-0cd1-4a24-b994-1061cca73bfb-copy.png"
                                    alt=""
                                    class="w-100"
                                />
                            </div>
                        </div>
                    </div>
                </b-container>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";

export default {
    data() {
        return {};
    },
    computed: {
        ...mapState(['locale'])
    }
};
</script>
