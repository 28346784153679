<template>
    <footer class="row mx-0 align-items-center main-footer bg-blur">
        <div class="footer-content">
            <p>{{ $t('title.footerText') }}<a target="_blank" href="https://www.checker-soft.com/">checker-soft</a></p>
        </div>
    </footer>
</template>

<script>
export default {
    name: "AppFooter",
};
</script>
