<template>
    <div>
        <b-card no-body>
            <b-tabs v-model="tabIndex" card>
                <b-tab :title="$t('title.manageSingleHelpRequest')" @click="tabIndex = 0" lazy>
                    <single-request />
                </b-tab>
                <b-tab :title="$t('title.manageEventHelpRequest')" @click="tabIndex = 1" lazy>
                    <event-request />
                </b-tab>
            </b-tabs>
        </b-card>
    </div>
</template>

<script>
import SingleRequest from "./singleRequest";
import EventRequest from "./eventRequest";

export default {
    components: { SingleRequest, EventRequest },
    data() {
        return {
            tabIndex: 0
        };
    }
};
</script>
