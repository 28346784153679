import Vue from 'vue';
import _ from 'lodash';
import store from './store';
import App from './App.vue';
// import './styles/main.scss';
import router from './router';
import { i18n } from './Localization';
import 'bootstrap/dist/css/bootstrap.css';
import '@fortawesome/fontawesome-free/css/all.css';
import NotifyMixin from "@/Util/NotifyMixin";
import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-default.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import VueTelInput from 'vue-tel-input';
import 'vue-tel-input/dist/vue-tel-input.css';
import { Datetime } from 'vue-datetime';
import 'vue-datetime/dist/vue-datetime.css';
import 'vue-select/dist/vue-select.css';
import { dateFormat, displayPhoneNumber, handleSyncRequestLoader, hasRole, utcDateToLocalDate } from "@/Util/auth";
import vSelect from 'vue-select';
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';
import Treeselect from "@riophae/vue-treeselect";
import '@riophae/vue-treeselect/dist/vue-treeselect.css';
import CrudTable from "@/components/CrudTable";
import VueGoogleAutocomplete from "vue-google-autocomplete";

const isRTL = true;
if ( isRTL ) {
    require('./styles/bootstrap/css/bootstrap-rtl.css');
    // require('bootstrap-v4-rtl/css/bootstrap-rtl.css');
} else {
    require('./styles/bootstrap/css/bootstrap.css');
    // require('bootstrap-v4-rtl/css/bootstrap.css');
}
require('./styles/main.scss');

Vue.config.productionTip = false;

Vue.component('datetime', Datetime);
Vue.component('v-select', vSelect);
Vue.component('treeselect', Treeselect);
Vue.component('crud-table', CrudTable);
Vue.component('vue-google-autocomplete', VueGoogleAutocomplete);

Vue.config.productionTip = false;

Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(VueTelInput);
Vue.use(VueToast, {
    position: 'top-right'
});

Vue.prototype._ = _;
Vue.prototype.$global = {
    dateFormat: (date, format = 'DD/MM/YYYY') => ( ( date ) ? dateFormat(date, format) : null ),
    hasRole: (role) => hasRole(role),
    displayPhoneNumber: (phone) => displayPhoneNumber(phone),
    utcDateToLocalDate: (date, toFormat = 'DD.MM.YYYY HH:mm', fromFormat = 'YYYY-MM-DD HH:mm:ss') => utcDateToLocalDate(date, toFormat, fromFormat),
};

Vue.mixin(NotifyMixin);

new Vue({
    store,
    router,
    i18n,
    render: h => h(App),
    created() {
        handleSyncRequestLoader(store, process.env.VUE_APP_API_URL);
    },
    mounted() {
        this.$nextTick(() => {
            this.setInputDirection(); // Set direction on mount
        });
    },
    methods: {
        setInputDirection(language) {
            const inputElements = document.querySelectorAll('.vti__input');
            inputElements.forEach((element) => {
                console.log(element); // For debugging, log the element
                element.setAttribute('dir', 'ltr'); // Set the `dir` attribute
            });
            // If the input element is found, set the `dir` attribute to 'ltr' for Hebrew
            // if ( inputElement ) {
            //     inputElement.setAttribute('dir', 'ltr');
            // }
        },
    }
}).$mount('#app');
