<template>
    <div>
        <b-modal v-model="isOpenModal" id="requestCompleteModal"  :hide-header="true"
                 :hide-footer="true">
            <p>{{ $t('title.completeRequestMessage') }}</p>
            <div class="modal-footer d-flex mr-2 gap-2">
                <button type="button" class="btn main-btn col m-0" @click="handleSubmitRequest">{{ $t('title.yes') }}</button>
                <button type="button" class="btn sec-btn col m-0" @click="cancelModal">
                    {{ $t('title.cancel') }}
                </button>
            </div>
        </b-modal>
    </div>
</template>

<script>
import {request} from "@/Util/Request";
import Error from "@/Util/Error";

export default {
    data() {
        return {
            isOpenModal: false,
            volunteerRequestId: null,
            formErrors: new Error({}),
        };
    },
    methods: {
        handleToggleModal(id) {
            this.isOpenModal = !this.isOpenModal;
            this.volunteerRequestId = id;
        },
        async handleSubmitRequest() {
            try {
                this.cancelModal();
                const response = await request({
                    method: 'put',
                    url: `/volunteer/applications/${ this.volunteerRequestId }`,
                    data: {}
                });
                if(response) {
                    this.$emit('refreshCard');
                    this.formErrors = new Error({});
                    this.notifySuccessWithMsg(`${ this.$t('title.helpRequestCompleteMessage') }`);
                }
            } catch (error) {
                if ( error.request && error.request.status && error.request.status === 422 ) {
                    this.formErrors = new Error(JSON.parse(error.request.responseText).errors);
                    return false;
                } else {
                    this.notifyError();
                }
            }
        },
        cancelModal() {
            this.isOpenModal = false;
        }
    }
};
</script>
