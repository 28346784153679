<template>
    <div class="main-block about-us text-center">
        <div>
            <div class="row mb-4 text-center">
                <div class="col">
                    <h2>{{ $t('title.eventVolunteerHelpRequest') }}</h2>
                    <p class="text-muted">{{ $t('title.eventStatusVerifyMessage') }}</p>
                </div>
            </div>
            <div class="col-12 text-center py-2" v-if="isLoading">
                <i class="fa fa-spin fa-spinner fa-2x"></i>
            </div>
            <div v-else>
                <div v-if="eventVolunteerDetail" class="row justify-content-center">
                    <div class="col-md-8">
                        <b-card
                            :header="$t('title.helpRequestDetails')"
                            header-tag="h4"
                            header-class="bg-primary text-white"
                            class="mb-4"
                        >
                            <p>
                                <strong>{{ $t('title.from') }}:</strong> {{ eventVolunteerDetail.event?.manager?.full_name }}
                            </p>
                            <p>
                                <strong>{{ $t('title.message') }}:</strong> {{ eventVolunteerDetail.event.name }} {{$t('title.eventRequestConfirmationMessage')}}
                            </p>
                            <p>
                                <strong>{{ $t('title.address') }}:</strong> {{ eventVolunteerDetail.event.address }}
                            </p>
                            <p>
                                <strong>{{ $t('title.createdAt') }}:</strong> {{ $global.utcDateToLocalDate(eventVolunteerDetail?.event?.created_at) }}
                            </p>
                            <p>
                                <strong>{{ $t('title.status') }}</strong> {{ (eventVolunteerDetail?.status == 'accept') ? $t('title.accepted') : (eventVolunteerDetail?.status == 'reject') ? $t('title.rejected') : eventVolunteerDetail?.status }}
                            </p>

                            <!-- Action Buttons -->
                            <div class="text-center mt-4" v-if="eventVolunteerDetail.status == 'pending'">
                                <b-button
                                    variant="success"
                                    class="mr-2"
                                    @click="handleAcceptRejectModal(eventVolunteerDetail.id, 'accept')"
                                    :disabled="isLoading"
                                >
                                    {{ $t('title.accept') }}
                                </b-button>
                                <b-button
                                    variant="danger"
                                    @click="handleAcceptRejectModal(eventVolunteerDetail.id, 'reject')"
                                    :disabled="isLoading"
                                >
                                    {{ $t('title.reject') }}
                                </b-button>
                            </div>
                        </b-card>
                    </div>
                </div>
                <div v-else class="row justify-content-center">
                    <div class="col-md-8">
                        <b-card
                            :header="(formErrors.has('code')) ? $t('title.requestInvalid') : $t('title.requestExpired')"
                            header-tag="h4"
                            header-class="bg-danger text-white"
                            class="mb-4 text-center"
                        >
                            <p class="text-muted">
                                {{formErrors.first('code')}}
                                {{formErrors.first('message')}}
                            </p>
                        </b-card>
                    </div>
                </div>
            </div>
        </div>

        <WithoutLoginVolunteerRequestAcceptRejectModal ref="volunteerAcceptRejectModal" @refreshVolunteerList="handleRefresh"/>
    </div>
</template>

<script>
import {request} from "@/Util/Request";
import Error from "@/Util/Error";
import WithoutLoginVolunteerRequestAcceptRejectModal from "@/view/pages/volunteer/request/WithoutLoginVolunteerRequestAcceptRejectModal";

export default {
    data() {
        return {
            code: null,
            formErrors: new Error({}),
            eventVolunteerDetail: null,
            isLoading: true,
        };
    },
    components: {
        WithoutLoginVolunteerRequestAcceptRejectModal
    },
    methods: {
        // Check if the request is expired
        async checkExpiration() {
            try {
                this.isLoading = true;
                const response = await request({
                    method: 'post',
                    url: `/volunteer/check-event-expiration-link`,
                    data: {
                        code: this.code
                    }
                });

                this.eventVolunteerDetail = response.data;
                this.isLoading = false;
            } catch (error) {
                this.isLoading = false;
                if ( error.request && error.request.status && error.request.status === 422 ) {
                    this.formErrors = new Error(JSON.parse(error.request.responseText).errors);
                    return false;
                } else {
                    this.notifyError();
                }
            }
        },
        handleAcceptRejectModal(id, status) {
            this.$refs['volunteerAcceptRejectModal'].handleToggleModal(id, status);
        },
        handleRefresh() {
            this.checkExpiration();
        }
    },
    mounted() {
        if (this.$route.params.code) {
            this.code = this.$route.params.code;
        }
        this.checkExpiration();
    },
};
</script>


