<template>
  <div>
      <b-button :variant="(userRequestDetail.is_approved) ? 'warning' : 'danger'"
                class="col-12 col-md-auto common-button small-btn" v-if="userRequestDetail.status == 'assigned'"
                @click="triggerModal(userRequestDetail)">
                    {{(userRequestDetail.is_approved) ? $t('title.assigned') : $t('title.pending')}}
      </b-button>
      <b-dropdown
          :id="'volunteer-management-dropdown-' + userRequestDetail.id"
          text=""
          class="volunteer-management-dropdown"
          v-else
    >
      <template #button-content>
        <b-button variant="primary" class="col-12 col-md-auto main-btn small-btn" @click="pendingVolunteerLists">
            {{$t('title.assign')}}
        </b-button>
      </template>
      <b-dropdown-item v-if="pendingVolunters.length == 0">
          {{ $t('title.notFoundVolunteer')}}
      </b-dropdown-item>
      <b-dropdown-item v-else v-for="volunteer in pendingVolunters" @click="triggerModal(volunteer)" :key="volunteer.index">
          {{ volunteer.volunteer.full_name}}
      </b-dropdown-item>
    </b-dropdown>
  </div>
</template>

<script>
import {request} from "@/Util/Request";

export default {
    props: {
        eventId: {
            type: Number,
            required: true,
        },
        userRequestDetail: {
            type: Object,
            required: true,
        }
    },
    data() {
        return {
            pendingVolunters: []
        }
    },
    mounted() {
        this.pendingVolunteerLists();
    },
    methods: {
        async pendingVolunteerLists() {
            try {
                const response = await request({
                    method: 'get',
                    url: `/event-volunteer-pending-requests?event_id=${ this.eventId }`
                });

                const { data } = response;
                this.pendingVolunters = data;
            } catch (error) {
                this.notifyError();
            }
        },
        triggerModal(volunteer) {
          this.$emit('open-modal', {volunteer:volunteer, userRequestId: this.userRequestDetail.id});
        },
  },
};
</script>
