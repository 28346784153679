<template>
    <div class="main-block new-help-request">
        <div class="div col-12 p-0 d-flex flex-wrap align-items-center">
            <h4>{{ $t('title.newHelpRequest') }}</h4>
            <div class="progress-container col-9 col-md-6 row gap-3 m-0 p-0 ms-auto my-4 my-md-0">
                <b-progress
                    :value="progress1"
                    max="100"
                    height="10px"
                    class="col p-0"
                    :class="`active-progress-${activeStep}`"
                >
                </b-progress>
                <b-progress
                    :value="progress2"
                    max="100"
                    height="10px"
                    class="col p-0"
                    :class="`active-progress-${activeStep}`"
                >
                </b-progress>
                <b-progress
                    :value="progress3"
                    max="100"
                    height="10px"
                    class="col p-0"
                    :class="`active-progress-${activeStep}`"
                >
                </b-progress>
            </div>
        </div>
        <div class="scrollable-block">
            <div class="col-12 row mt-3 m-0 p-0">
                <b-form @submit.stop.prevent="onSubmit" class="col-12 col-md-6 mx-auto p-0">
                    <div class="mb-3" v-if="!formFields.selectedType">
                        <label for="type">{{ $t('title.chooseHelpType') }}</label>
                        <treeselect :multiple="false" v-model="formFields.category_id" :options="dropdowns.type_options"
                                    :placeholder="$t('title.select')"
                                    id="category_id"></treeselect>
                        <b-button type="submit" variant="primary" class="mt-3 w-100" :disabled="!formFields.category_id"
                        >{{ $t('title.toNextStep') }}
                        </b-button>
                    </div>

                    <div v-else-if="transportation_form" class="mb-3">
                        <h5>{{ $t('title.nextStep') }}: {{
                                ( _.find(dropdowns.type_options, (i) => i.id == formFields.category_id) || {} ).label
                            }}
                            {{ $t('title.details') }}</h5>
                        <div class="mb-3">
                            <label for="rideTo">{{ $t('title.needRideTo') }} <span class="mandatory">*</span></label>
                            <input
                                :class="((showError && !isValidRideTo) || formErrors.has('destination_address'))  ? 'is-invalid' : ''"
                                type="text"
                                v-model="formFields.destination_address"
                                @input="search('destination_address');resetError;removeError('destination_address')"
                                @focus="onFocus('destination_address')"
                                @blur="handleBlur"
                                :placeholder="$t('title.searchLocation')"
                                class="autocomplete-input form-control"
                            />
                            <ul class="autocomplete-list">
                                <li
                                    v-for="(suggestion, index) in dropdowns.addresses.destination_address"
                                    :key="index"
                                    @click="selectSuggestion(suggestion,'destination_address')"
                                >
                                        <span v-if="suggestion.id == 'default'">
                                             <b-spinner small variant="primary" type="grow"
                                                        label="Loading..."></b-spinner>
                                        </span>
                                    {{ suggestion.label }}
                                </li>
                            </ul>
                            <p class="text-danger" v-if="formErrors.has('destination_address')">
                                {{ formErrors.first('destination_address') }}
                            </p>
                            <b-form-invalid-feedback v-if="showError && !isValidRideTo">
                                {{ $t('title.destinationAddressLonger') }}
                            </b-form-invalid-feedback>
                        </div>

                        <div class="mb-3">
                            <label for="origin_address">{{ $t('title.iAmFrom') }}<span
                                class="mandatory">*</span></label>
                            <input
                                type="text"
                                :class="((showError && !isValidFromPoint) || formErrors.has('origin_address'))  ? 'is-invalid' : ''"
                                v-model="formFields.origin_address"
                                @input="search('origin_address');resetError;removeError('origin_address')"
                                @focus="onFocus('origin_address')"
                                @blur="handleBlur"
                                :placeholder="$t('title.searchLocation')"
                                class="autocomplete-input form-control"
                            />
                            <ul class="autocomplete-list">
                                <li
                                    v-for="(suggestion, index) in dropdowns.addresses.origin_address"
                                    :key="index"
                                    @click="selectSuggestion(suggestion,'origin_address')"
                                >
                                        <span v-if="suggestion.id == 'default'">
                                             <b-spinner small variant="primary" type="grow"
                                                        label="Loading..."></b-spinner>
                                        </span>
                                    {{ suggestion.label }}
                                </li>
                            </ul>
                            <p class="text-danger" v-if="formErrors.has('origin_address')">
                                {{ formErrors.first('origin_address') }}
                            </p>
                            <b-form-invalid-feedback v-if="showError && !isValidFromPoint">
                                {{ $t('title.originAddressLonger') }}
                            </b-form-invalid-feedback>
                        </div>

                        <div class="mb-3">
                            <label for="arrival_date"
                            >{{ $t('title.wantToBeThereOn') }} <span class="mandatory">*</span></label
                            >
                            <datetime
                                type="date"
                                input-id="arrival_date"
                                name="arrival_date"
                                :input-class="
                  ((showError && !isValidDateTransportation) || formErrors.has('arrival_date'))
                    ? 'form-control is-invalid'
                    : 'form-control'
                "
                                v-model="formFields.arrival_date"
                                :min-datetime="new Date().toISOString()"
                                @input="dateChangeInput;resetError;removeError('arrival_date')"
                                :auto="true"
                                :format="{
                                  year: 'numeric',
                                  month: 'numeric',
                                  day: 'numeric',
                                }"
                            ></datetime>
                            <p class="text-danger" v-if="formErrors.has('arrival_date')">
                                {{ formErrors.first('arrival_date') }}
                            </p>
                            <b-form-invalid-feedback
                                :class="showError && !isValidDateTransportation ? 'd-block' : 'd-none'"
                            >
                                {{ $t('title.selectFutureDate') }}
                            </b-form-invalid-feedback>
                        </div>
                        <div class="mb-3">
                            <label for="arrival_time"
                            >{{ $t('title.at') }} <span class="mandatory">*</span></label
                            >
                            <datetime
                                type="time"
                                input-id="arrival_time"
                                name="arrival_time"
                                :input-class="((showError && !isValidDateTransportation) || formErrors.has('arrival_time')) ? 'form-control is-invalid' : 'form-control'"
                                v-model="formFields.arrival_time"
                                @input="resetError;removeError('arrival_time')"
                                :min-datetime="new Date().toISOString()"
                                :format="{
                                  hour: 'numeric',
                                  minute: '2-digit',
                                }"
                            ></datetime>
                            <p class="text-danger" v-if="formErrors.has('arrival_time')">
                                {{ formErrors.first('arrival_time') }}
                            </p>
                            <b-form-invalid-feedback
                                :class="showError && !isValidDateTransportation ? 'd-block' : 'd-none'"
                            >
                                {{ $t('title.selectFutureTime') }}
                            </b-form-invalid-feedback>
                        </div>

                        <div>
                            <label for="description"
                            >{{ $t('title.requestDetailsDescription') }} <span class="mandatory">*</span></label
                            >
                            <b-form-textarea
                                @input="resetError;removeError('description')"
                                v-model="formFields.description"
                                id="description"
                                rows="3"
                                max-rows="6"
                                :input-class="((showError && !isValidDescription) || formErrors.has('description')) ? 'form-control is-invalid' : 'form-control'"
                                :class="((showError && !isValidDescription) || formErrors.has('description')) ? 'is-invalid' : ''"
                            ></b-form-textarea>
                            <p class="text-danger" v-if="formErrors.has('description')">
                                {{ formErrors.first('description') }}
                            </p>
                            <b-form-invalid-feedback v-if="showError && !isValidDescription">
                                {{ $t('title.descriptionLonger') }}
                            </b-form-invalid-feedback>
                        </div>
                        <b-button type="submit" variant="primary" class="mt-3 mb-3 w-100">{{
                                $t('title.toTheNextStep')
                            }}
                        </b-button>
                        <a href="#" @click.prevent="backStep(0)">{{ $t('title.goBack') }}</a>
                    </div>

                    <div v-else-if="form_2" class="mb-3">
                        <h5>{{ $t('title.nextStep') }}: {{
                                ( _.find(dropdowns.type_options, (i) => i.id == formFields.category_id) || {} ).label
                            }}
                            {{ $t('title.details') }}</h5>
                        <div class="mb-3">
                            <label for="description"
                            >{{ $t('title.requestDetailsDescription') }} <span class="mandatory">*</span></label
                            >
                            <b-form-textarea
                                @input="resetError;removeError('description')"
                                v-model="formFields.description"
                                id="description"
                                rows="3"
                                max-rows="6"
                                :input-class="((showError && !isValidDescription) || formErrors.has('description')) ? 'form-control is-invalid' : 'form-control'"
                                :class="((showError && !isValidDescription) || formErrors.has('description')) ? 'is-invalid': ''"
                            ></b-form-textarea>
                            <p class="text-danger" v-if="formErrors.has('description')">
                                {{ formErrors.first('description') }}
                            </p>
                            <b-form-invalid-feedback v-if="showError && !isValidDescription">
                                {{ $t('title.descriptionLonger') }}
                            </b-form-invalid-feedback>
                        </div>
                        <div class="mb-3">
                            <label for="expiration_date">{{ $t('title.untilRequestRelevant') }}<span
                                class="mandatory">*</span></label>
                            <datetime
                                input-id="expiration_date"
                                name="expiration_date"
                                type="date"
                                :input-class="((showError && !isValidexpiration_date) || formErrors.has('expiration_date')) ? 'form-control is-invalid' : 'form-control'"
                                :min-datetime="new Date().toISOString()"
                                v-model="formFields.expiration_date"
                                @input="expirationDateChangeInput;resetError;removeError('expiration_date')"
                                :auto="true"
                                :format="{
                                    year: 'numeric',
                                    month: '2-digit',
                                    day: '2-digit'
                                }"
                            />
                            <p class="text-danger" v-if="formErrors.has('expiration_date')">
                                {{ formErrors.first('expiration_date') }}
                            </p>
                            <b-form-invalid-feedback
                                :class="showError && !isValidexpiration_date ? 'd-block' : 'd-none'"
                            >
                                {{ $t('title.selectFutureDate') }}
                            </b-form-invalid-feedback>
                        </div>

                        <b-button type="submit" variant="primary" class="mb-3 w-100">{{
                                $t('title.goToNextStep')
                            }}
                        </b-button>
                        <a href="#" @click.prevent="backStep(0)">{{ $t('title.goBack') }}</a>
                    </div>

                    <div v-else class="mb-3">
                        <h5>{{ $t('title.personalDetails') }}</h5>
                        <div class="mb-3">
                            <label for="full_name">{{ $t('title.fullName') }} <span class="mandatory">*</span></label>
                            <b-form-input
                                @input="resetError;removeError('full_name')"
                                v-model="formFields.full_name"
                                :state="showError ? isValid : null"
                                id="full_name"
                            >
                            </b-form-input>

                            <b-form-invalid-feedback v-if="showError && !isValid">
                                {{ $t('title.fullNameLonger') }}
                            </b-form-invalid-feedback>
                            <b-form-invalid-feedback
                                :state="!formErrors.has('full_name')">
                                {{ formErrors.first('full_name') }}
                            </b-form-invalid-feedback>
                        </div>
                        <div class="mb-3">
                            <label for="phone">{{ $t('title.phone') }} <span class="mandatory">*</span></label>
                            <vue-tel-input
                                :class="formErrors.has('phone') ? `error-input` : ``"
                                v-model="formFields.phone"
                                mode="national"
                                v-bind="phone.options"
                                :autoFormat="false"
                                validCharactersOnly
                                @focus="resetError"
                                @input="onInput;removeError('phone')"
                                required
                            />
                            <b-form-invalid-feedback
                                :state="!formErrors.has('phone')">
                                {{ formErrors.first('phone') }}
                            </b-form-invalid-feedback>
                        </div>
                        <div class="mb-3">
                            <label for="email">{{ $t('title.email') }} <span class="mandatory">*</span></label>
                            <b-form-input
                                @input="resetError;removeError('email')"
                                v-model="formFields.email"
                                :state="showError ? isValidEmail : null"
                                id="email"
                            >
                            </b-form-input>
                            <b-form-invalid-feedback
                                :state="!formErrors.has('email')">
                                {{ formErrors.first('email') }}
                            </b-form-invalid-feedback>
                        </div>
                        <div class="mb-3">
                            <label for="type">{{ $t('title.accessibilityAndDisabilityInfo') }}</label>
                            <treeselect @input="resetError;removeError('disability_id')"
                                        :multiple="false" v-model="formFields.disability_id"
                                        :options="dropdowns.disabilities"
                                        :placeholder="$t('title.select')"
                                        :class="((showError && !isValidDisability) || formErrors.has('disability_id')) ? 'is-invalid' : ''"
                                        id="type"></treeselect>
                            <b-form-invalid-feedback
                                :state="!formErrors.has('disability_id')">
                                {{ formErrors.first('disability_id') }}
                            </b-form-invalid-feedback>
                        </div>
                        <div class="mb-3">
                            <label for="comments">{{ $t('title.comments') }}:</label>
                            <b-form-textarea
                                @input="resetError;removeError('comments')"
                                v-model="formFields.comments"
                                id="comments"
                                rows="3"
                                max-rows="6"
                            ></b-form-textarea>
                        </div>
                        <div class="mb-3">
                            <label for="residential_address">{{ $t('title.residentialAddress') }}<span
                                class="mandatory">*</span></label>
                            <input
                                type="text"
                                v-model="formFields.residential_address"
                                :class="((showError && !isValidResidentialPoint) || formErrors.has('residential_address'))  ? 'is-invalid' : ''"
                                @input="search('residential_address');resetError;removeError('residential_address')"
                                @focus="onFocus('residential_address')"
                                :placeholder="$t('title.searchLocation')"
                                class="autocomplete-input form-control"
                            />
                            <ul class="autocomplete-list">
                                <li
                                    v-for="(suggestion, index) in dropdowns.addresses.residential_address"
                                    :key="index"
                                    @click="selectSuggestion(suggestion,'residential_address')"
                                >
                                        <span v-if="suggestion.id == 'default'">
                                             <b-spinner small variant="primary" type="grow"
                                                        label="Loading..."></b-spinner>
                                        </span>
                                    {{ suggestion.label }}
                                </li>
                            </ul>
                            <b-form-invalid-feedback
                                :state="!formErrors.has('residential_address')">
                                {{ formErrors.first('residential_address') }}
                            </b-form-invalid-feedback>
                        </div>
                        <div class="mb-3">
                            <label for="arrival_address">{{ $t('title.arrivalAddress') }}</label>
                            <input
                                type="text"
                                v-model="formFields.arrival_address"
                                @input="search('arrival_address');resetError;removeError('arrival_address')"
                                @focus="onFocus('arrival_address')"
                                :placeholder="$t('title.searchLocation')"
                                class="autocomplete-input form-control"
                            />
                            <ul class="autocomplete-list">
                                <li
                                    v-for="(suggestion, index) in dropdowns.addresses.arrival_address"
                                    :key="index"
                                    @click="selectSuggestion(suggestion,'arrival_address')"
                                >
                                        <span v-if="suggestion.id == 'default'">
                                            <b-spinner small variant="primary" type="grow"
                                                       label="Loading..."></b-spinner>
                                        </span>
                                    {{ suggestion.label }}
                                </li>
                            </ul>

                            <b-form-invalid-feedback
                                :state="!formErrors.has('arrival_address')">
                                {{ formErrors.first('arrival_address') }}
                            </b-form-invalid-feedback>
                        </div>
                        <div class="mb-3">
                            <b-form-group :label="$t('title.youCanContactMeAt')">
                                <div class="d-flex">
                                    <div class="">
                                        <b-form-checkbox
                                            id="checkbox-1"
                                            v-model="formFields.is_phone"
                                            name="checkbox-1"
                                            value="1"
                                            unchecked-value="0"
                                            @input="resetError;removeError('is_phone')"
                                        >
                                            {{ $t('title.phone') }}
                                        </b-form-checkbox>
                                        <b-form-invalid-feedback
                                            :state="!formErrors.has('is_phone')">
                                            {{ formErrors.first('is_phone') }}
                                        </b-form-invalid-feedback>
                                    </div>
                                    <div class="ml-3">
                                        <b-form-checkbox
                                            id="checkbox-2"
                                            v-model="formFields.is_wp"
                                            name="checkbox-2"
                                            value="1"
                                            unchecked-value="0"
                                            @input="resetError;removeError('is_wp')"
                                        >
                                            {{ $t('title.whatsApp') }}
                                        </b-form-checkbox>
                                        <b-form-invalid-feedback
                                            :state="!formErrors.has('is_wp')">
                                            {{ formErrors.first('is_wp') }}
                                        </b-form-invalid-feedback>
                                    </div>
                                </div>
                            </b-form-group>
                        </div>
                        <b-button :disabled="loader" type="submit" variant="primary" class="mb-3 w-100"
                        >{{ $t('title.createNewHelpRequest') }}
                        </b-button>
                        <div class="d-flex justify-content-between">
                            <a href="#" @click="backStep(1)">{{ $t('title.goBack') }}</a>
                            <a href="#" @click="newHelpRequest">{{ $t('title.createNewHelpRequest') }}</a>
                        </div>
                    </div>
                </b-form>
            </div>
        </div>
        <UserPersonalDetailChangeConfirmationModal
            ref="userDetailChangeModal"
            @submitNewHelpRequest="submitNewHelpRequest"
        />
    </div>
</template>

<script>
import { request } from "@/Util/Request";
import Error from "@/Util/Error";
import { mapState } from "vuex";
import UserPersonalDetailChangeConfirmationModal from "./modal/UserPersonalDetailChangeConfirmationModal";
import destinationMixin from "@/Util/destinationMixin";
import { ASYNC_SEARCH } from '@riophae/vue-treeselect';
import { getAuthUser } from "@/Util/auth";
import { reactive } from "vue";

const simulateAsyncOperation = fn => {
    setTimeout(fn, 2000);
};

const phone = reactive({
    value: "",
    isBlurred: false,
    inputValue: {
        formatted: "",
        valid: false,
    },
    options: {
        autoFormat: true,
        inputOptions: {
            showDialCode: false,
            showDialCodeInList: true,
        },
        mode: "international",
        validCharactersOnly: true,
    },
});

const FORM_STATE = {
    selectedType: false,
    category_id: null,
    arrival_date: null,
    arrival_time: null,
    expiration_date: null,
    description: '',
    full_name: null,
    phone: null,
    email: null,
    disability_id: null,
    comments: null,
    residential_address: null,
    arrival_address: null,
    origin_address: null,
    status: null,
    is_phone: false,
    is_wp: false,
    destination_address: null
};


export default {
    mixins: [destinationMixin],
    data() {
        return {
            personal_details: false,
            form_2: false,
            isValidP: false,
            transportation_form: false,
            showError: false,
            dropdowns: {
                type_options: [],
                disabilities: []
            },
            options: [
                { text: this.$t('title.phone'), value: 'phone' },
                { text: this.$t('title.whatsApp'), value: 'whatsapp' },
            ],
            inputOptions: {
                placeholder: '',
            },
            activeStep: 0,
            progress1: 0,
            progress2: 0,
            progress3: 0,
            formFields: { ...FORM_STATE },
            formErrors: new Error({}),
            phone: phone,
        };
    },
    components: {
        UserPersonalDetailChangeConfirmationModal
    },
    computed: {
        isValid() {
            return ( this.formFields.full_name || {} ).length >= 5 && ( this.formFields.full_name || {} ).length <= 255;
        },
        isValidDisability() {
            return ( this.formFields.disability_id > 0 );
        },
        isValidEmail() {
            return ( this.formFields.email || {} ).length >= 5 && ( this.formFields.email || {} ).length <= 255;
        },
        isValidA() {
            return ( this.formFields.residential_address || {} ).length >= 5;
        },
        computedState() {
            if ( !this.showError ) {
                return 'no-result';
            }
            return this.isValidP ? 'correct' : 'wrong-number';
        },
        isValidDateTransportation() {
            return !( this.formFields.arrival_date.length <= 0 );
        },
        isValidexpiration_date() {
            return !( this.formFields.expiration_date.length <= 0 );
        },
        isValidDescription() {
            return this.formFields.description.length >= 5;
        },
        isValidRideTo() {
            return ( this.formFields.destination_address || {} ).length >= 5;
        },
        isValidFromPoint() {
            return ( this.formFields.origin_address || {} ).length >= 5;
        },
        isValidResidentialPoint() {
            return ( this.formFields.residential_address || {} ).length >= 5;
        },
        progress() {
            const totalFields = this.getTotalFields();
            const filledFields = this.getFilledFields();
            return totalFields > 0 ? Math.round(( filledFields / totalFields ) * 100) : 0;
        },
        ...mapState(['locale', 'user', 'loader'])
    },
    methods: {
        async removeError(key){
            if ( typeof key === `object` ) {
                for (let i = 0; i < key.length; i++) {
                    this.formErrors.remove(key[i]);
                }
            } else {
                this.formErrors.remove(key);
            }
        },
        onInput(formattedNumber, input) {
            this.resetError();
            this.phone.inputValue = input;
            if ( input && input.nationalNumber ) {
                setTimeout(() => {
                    this.formFields.phone = input.nationalNumber;
                }, 0);
            }
        },
        resetError() {
            this.formErrors = new Error({});
            this.showError = false;
        },
        dateChangeInput(value) {
            this.formFields.arrival_date = value;
        },
        expirationDateChangeInput(value) {
            this.formFields.expiration_date = value;
        },
        newHelpRequest() {
            this.activeStep = 0;
            this.formFields.selectedType = false;
            this.showError = false;
            this.transportation_form = false;
            ( this.form_2 = false ),
                ( this.personal_details = false );

            this.progress1 = 0;
            this.progress2 = 0;
            this.progress3 = 0;
            this.formFields = { ...FORM_STATE };
            this.formErrors = new Error({});
        },
        backStep(stepNo) {
            if ( stepNo == 0 ) {
                this.formFields.selectedType = false;
            }

            if ( stepNo == 1 ) {
                if ( ( _.find(this.dropdowns.type_options, (i) => i.id == this.formFields.category_id) || {} ).type == '1' ) {
                    this.transportation_form = true;
                    this.form_2 = false;
                } else {
                    this.form_2 = true;
                    this.transportation_form = false;
                }
            }
            this.activeStep = stepNo;
        },
        async updateProgress() {
            // Wait until the DOM is fully updated

            // Trigger reactivity for progress calculation
            this.progress;
        },

        getTotalFields() {
            if ( !this.formFields.selectedType ) {
                return 1; // Step 1: Only 1 required field (type selection)
            } else if ( this.transportation_form ) {
                return 4; // Step 2: 4 fields for transportation type + 5 personal details fields
            } else if ( this.form_2 ) {
                return 2; // Step 2: 2 fields for other types + 5 personal details fields
            } else if ( this.personal_details ) {
                return 5;
            }
            return 0;
        },

        getFilledFields() {
            let filledCount = 0;

            // Step 1: Type selection
            if ( !this.formFields.selectedType && this.formFields.category_id ) {
                filledCount += 1;
                this.activeStep = 1;
            }

            // Step 2: Transportation form
            if ( this.transportation_form ) {
                filledCount += this.formFields.destination_address ? 1 : 0;
                filledCount += this.formFields.origin_address ? 1 : 0;
                filledCount += this.formFields.arrival_date ? 1 : 0;
                filledCount += this.formFields.description ? 1 : 0;
                this.activeStep = 2;
            }

            // Step 2: Form 2 for other types
            if ( this.form_2 ) {
                filledCount += this.formFields.expiration_date ? 1 : 0;
                filledCount += this.formFields.description ? 1 : 0;
                this.activeStep = 2;
            }

            // Step 3: Personal details
            if ( this.personal_details ) {
                if ( this.formFields.full_name ) {
                    filledCount++;
                }
                if ( this.formFields.phone ) {
                    filledCount++;
                }
                if ( this.formFields.comments ) {
                    filledCount++;
                }
                if ( this.formFields.residential_address ) {
                    filledCount++;
                }
                if ( this.formFields.is_phone || this.formFields.is_wp ) {
                    filledCount++;
                }
                this.activeStep = 3;
            }

            return filledCount;
        },
        async submitNewHelpRequest(isUsePreviousDetail = false) {
            if ( !this.phone.inputValue.valid ) {
                this.formErrors = new Error({
                    phone: [this.$t('title.enterValidPhoneNumber')]
                });
            } else {
                try {

                    const selectedCategoryType = ( _.find(this.dropdowns.type_options, (i) => i.id == this.formFields.category_id) || {} ).type;

                    const conversion_date = selectedCategoryType == 2 ? new Date(this.formFields.expiration_date) : new Date(this.formFields.arrival_date);
                    const month = String(conversion_date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
                    const day = String(conversion_date.getDate()).padStart(2, '0');
                    const year = conversion_date.getFullYear();
                    const date = `${ year }-${ month }-${ day }`;
                    const time = conversion_date.toLocaleTimeString(this.locale, {
                        hour: '2-digit', minute: '2-digit', hour12: false
                    });

                    const response = await request({
                        method: 'post',
                        url: `/user-applications`,
                        data: {
                            ...this.formFields,
                            'phone': ( this.phone.inputValue || {} ).number,
                            'is_use_previous_detail': isUsePreviousDetail,
                            'expiration_date': selectedCategoryType == 2 ? date : null,
                            'arrival_date': selectedCategoryType == 1 ? date : null,
                            'arrival_time': selectedCategoryType == 1 ? time : null
                        }
                    });

                    this.formFields = { ...FORM_STATE };
                    this.formErrors = new Error({});
                    this.showToast();
                    this.showError = false;
                    this.transportation_form = false;
                    ( this.form_2 = false ),
                        ( this.personal_details = false );

                    this.progress1 = 0;
                    this.progress2 = 0;
                    this.progress3 = 0;
                    this.$refs['userDetailChangeModal'].cancelModal();

                } catch (error) {
                    this.$refs['userDetailChangeModal'].cancelModal();
                    if ( error.request && error.request.status && error.request.status === 422 ) {
                        this.formErrors = new Error(JSON.parse(error.request.responseText).errors);
                        if ( this.formErrors.$errors && this.formErrors.$errors.error ) {
                            this.notifyErrorWithMsg(this.formErrors.$errors.error[0]);
                        }
                        return false;
                    }
                }
            }
        },
        async onSubmit() {
            if ( !this.formFields.selectedType && this.formFields.category_id ) {
                this.formFields.selectedType = true;
                if ( ( _.find(this.dropdowns.type_options, (i) => i.id == this.formFields.category_id) || {} ).type == '1' ) {
                    this.transportation_form = true;
                    this.form_2 = false;
                } else {
                    this.form_2 = true;
                    this.transportation_form = false;
                }
            } else if ( this.transportation_form ) {
                if (
                    this.isValidRideTo &&
                    this.isValidFromPoint &&
                    this.isValidDateTransportation &&
                    this.isValidDescription
                ) {
                    this.transportation_form = false;
                    this.personal_details = true;
                    this.showError = false;
                } else {
                    this.showError = true;
                }
            } else if ( this.form_2 ) {
                if ( this.isValidexpiration_date && this.isValidDescription ) {
                    this.form_2 = false;
                    this.personal_details = true;
                    this.showError = false;
                } else {
                    this.showError = true;
                }
            } else if ( this.personal_details ) {
                if (
                    this.isValid &&
                    this.isValidA &&
                    this.computedState != 'wrong-number' &&
                    this.formFields.phone.length > 0
                ) {

                    // axios
                    if ( !this.phone.inputValue.valid ) {
                        this.formErrors = new Error({
                            phone: [this.$t('title.enterValidPhoneNumber')]
                        });
                    } else {
                        try {

                            const selectedCategoryType = ( _.find(this.dropdowns.type_options, (i) => i.id == this.formFields.category_id) || {} ).type;

                            const conversion_date = selectedCategoryType == 2 ? new Date(this.formFields.expiration_date) : new Date(this.formFields.arrival_date);
                            const month = String(conversion_date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
                            const day = String(conversion_date.getDate()).padStart(2, '0');
                            const year = conversion_date.getFullYear();
                            const date = `${ year }-${ month }-${ day }`;
                            const time = conversion_date.toLocaleTimeString(this.locale, {
                                hour: '2-digit', minute: '2-digit', hour12: false
                            });

                            const response = await request({
                                method: 'post',
                                url: `/user-applications/check-user-detail`,
                                data: {
                                    ...this.formFields,
                                    'phone': ( this.phone.inputValue || {} ).number,
                                    'expiration_date': selectedCategoryType == 2 ? date : null,
                                    'arrival_date': selectedCategoryType == 1 ? date : null,
                                    'arrival_time': selectedCategoryType == 1 ? time : null
                                }
                            });

                            const { data } = response;

                            if ( data.change_fields.length > 0 ) {
                                this.$refs['userDetailChangeModal'].handleToggleModal(data.change_fields);
                            } else {
                                await this.submitNewHelpRequest();
                            }
                        } catch (error) {
                            this.$refs['userDetailChangeModal'].cancelModal();
                            if ( error.request && error.request.status && error.request.status === 422 ) {
                                this.formErrors = new Error(JSON.parse(error.request.responseText).errors);
                                if ( this.formErrors.$errors && this.formErrors.$errors.error ) {
                                    this.notifyErrorWithMsg(this.formErrors.$errors.error[0]);
                                }
                                return false;
                            }
                        }
                    }
                } else {
                    this.showError = true;
                }
            }
            this.updateProgress();
        },
        async getDropdowns() {
            try {
                const response = await request({
                    method: 'post',
                    url: `/dropdowns/application`
                });

                const { categories } = response;
                this.dropdowns.type_options = categories;

                const registerResponse = await request({
                    method: 'post',
                    url: `/dropdowns/register`
                });

                const { disabilities } = registerResponse;
                this.dropdowns.disabilities = disabilities;

                // Initialize progress on load
                this.updateProgress();

            } catch (error) {
            }
        },
        showToast() {
            const h = this.$createElement;
            const vNodesMsg = h('p', { class: ['text-center', 'mb-0'] }, [` Form was succesfully sent `]);
            // Create the title
            const vNodesTitle = h(
                'div',
                { class: ['d-flex', 'flex-grow-1', 'align-items-baseline', 'mr-2'] },
                [h('strong', { class: 'mr-2' }, 'Form was sent')]
            );
            // Pass the VNodes as an array for message and title
            this.$bvToast.toast([vNodesMsg], {
                title: [vNodesTitle],
                solid: true,
                variant: 'success',
            });
        },
        loadOptions({ action, searchQuery, callback }) {
            if ( action === ASYNC_SEARCH ) {
                simulateAsyncOperation(() => {
                    this.search(searchQuery)
                    .then((options) => {
                        callback(null, options); // Pass the options to the callback
                    })
                    .catch((error) => {
                        callback(error, []); // Handle errors and pass an empty array
                    });
                });
            }
        },
    },
    mounted() {
        if ( getAuthUser() && ( this.user != null ) ) {
            //if user login then does not allow to access this one page
            this.$router.push({ name: '404' });
        }

        this.getDropdowns();
    },
    watch: {
        activeStep(newStep) {
            if ( newStep === 1 ) {
                this.progress1 = this.progress;
                this.progress2 = 0;
                this.progress3 = 0;
            } else if ( newStep === 2 ) {
                this.progress1 = 100;
                this.progress2 = this.progress;
                this.progress3 = 0;
            } else if ( newStep === 3 ) {
                this.progress1 = 100;
                this.progress2 = 100;
                this.progress3 = this.progress;
            }
        },
        progress(newProgress) {
            if ( this.activeStep === 1 ) {
                this.progress1 = newProgress;
            } else if ( this.activeStep === 2 ) {
                this.progress2 = newProgress;
            } else if ( this.activeStep === 3 ) {
                this.progress3 = newProgress;
            }
        },
    },
};
</script>
