<template>
    <div
        id="myMap"
        style="
      border: 0;
      position: fixed;
      left: 0px;
      right: 0px;
      top: 0px;
      bottom: 0px;
      width: 100%;
      height: 100%;
    "
    ></div>
</template>

<script>
import * as atlas from 'azure-maps-control';

export default {
    props: {
        volunteerPoints: {
            type: Array,
            required: true,
        },
        userPoints: {
            type: Array,
            required: true,
        },
    },
    name: 'AzureMap',
    data() {
        return {
            map: null,
            datasource: null,
            popup: null,
            searchQuery: '',
            searchResults: [],
            minSearchInputLength: 3,
            keyStrokeDelay: 150,
            searchTimeout: null,
        };
    },
    mounted() {
        this.initializeMap();
    },
    methods: {
        initializeMap() {
            let longitude = (this.volunteerPoints.length > 0) ? this.volunteerPoints[0].lng : this.userPoints[0].lng;
            let latitude = (this.volunteerPoints.length > 0) ? this.volunteerPoints[0].lt : this.userPoints[0].lt;

            this.map = new atlas.Map('myMap', {
                center: [parseFloat(longitude), parseFloat(latitude)],
                zoom: 14,
                view: 'Auto',
                authOptions: {
                    authType: 'subscriptionKey',
                    subscriptionKey:
                        '1hINXj3kC6I4HcLTx8BoOuQEmFIzqHTFU4jyw757ux0SrUN8UPq9JQQJ99ALACi5Ypz5fxBSAAAgAZMP2Lh0',
                },
            });

            this.popup = new atlas.Popup();
            this.map.events.add('ready', this.onMapReady);
        },

        onMapReady() {
            this.datasource = new atlas.source.DataSource();
            this.map.sources.add(this.datasource);

            this.userPoints.forEach(point => {
                this.datasource.add(
                    new atlas.data.Feature(
                        new atlas.data.Point([parseFloat(point.lng), parseFloat(point.lt)]),
                        {
                            subType: 'UserPoint',
                            radius: 5,
                        }
                    )
                );
            });

            // Add second group points to the data source
            this.volunteerPoints.forEach(point => {
                this.datasource.add(
                    new atlas.data.Feature(
                        new atlas.data.Point([parseFloat(point.lng), parseFloat(point.lt)]),
                        {
                            subType: 'VolunteerGroup',
                            radius: 5, // Radius of the bubble in pixels
                        }
                    )
                );
            });

            this.map.layers.add(
                new atlas.layer.PolygonLayer(this.datasource, null, {
                    filter: ['==', 'subType', 'Circle'],
                    fillColor: 'rgba(98, 30, 125, 0.4)',
                })
            );

            this.map.layers.add(
                new atlas.layer.BubbleLayer(this.datasource, null, {
                    filter: ['==', 'subType', 'CenterPoint'],
                    color: 'rgb(98, 30, 125)',
                    radius: ['get', 'radius'],
                })
            );

            this.map.layers.add(
                new atlas.layer.BubbleLayer(this.datasource, null, {
                    filter: ['==', 'subType', 'UserPoint'],
                    strokeColor: 'rgb(240, 24, 24)',
                    strokeWidth: 2,
                    color: 'rgb(240, 24, 24)',
                    radius: ['get', 'radius'],
                })
            );

            this.map.layers.add(
                new atlas.layer.BubbleLayer(this.datasource, null, {
                    filter: ['==', 'subType', 'VolunteerGroup'],
                    strokeColor: 'rgb(98, 30, 125)',
                    strokeWidth: 2,
                    color: 'rgb(30, 247, 80)',
                    radius: ['get', 'radius'],
                })
            );
        },
    },
};
</script>

<style scoped>
#myMap {
    border: 0;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
}
</style>
