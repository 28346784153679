<template>
    <div class="col-12 emergency-history">
        <div class="div col-12 p-0 d-flex align-items-start">
            <h4 class="">{{ $t('title.emergencyRequests') }}</h4>
        </div>
        <div class="scrollable-block">
            <div class="col-12 row mt-3 m-0 p-0" style="min-height: 90%">
                <crud-table :columns="columns" :list-url="listUrl" ref="emergency-table" v-on:refresh="resetFilter"
                            :filters="filters">
                    <template #cell(volunteer)="{item}">
                        {{ ( item.volunteer || {} ).full_name }}
                    </template>
                    <template #cell(created_at)="{item}">
                        {{ item.created_at ? $global.utcDateToLocalDate(item.created_at) : null }}
                    </template>
                    <template #cell(status)="{item}">
                        {{ item.status }}
                    </template>
                    <template #cell(actions)="{item}">
                        <b-button v-if="item.volunteer && item.status == 'assigned'"
                                  :title="$t('title.close')"
                                  v-b-tooltip.hover.right
                                  variant="outline-primary"
                                   @click="openModalWithId(item.id)"
                                  class="mr-1" v-b-modal.event-user-request-close>
                            <b-icon icon="person-x" class="icon-style"></b-icon>
                        </b-button>
                    </template>
                </crud-table>
            </div>
        </div>

        <b-modal
            id="event-user-request-close"
            :title="$t('title.confirmation')"
            :centered="true"
            cancel-variant="outline-primary"
            :ok-title="$t('title.yes')"
            :cancel-title="$t('title.no')"
            :hide-footer="true"
        >
            <b-form>
                <div class="row">
                    <div class="col-12">
                        {{ $t('title.areYouSureWantToCloseRequest') }}
                    </div>
                    <div class="col-12">
                        <hr />
                    </div>
                    <div class="col-12 text-right">
                        <div class="btn-group">
                            <b-button variant="primary" class="mr-2" @click="handleCloseRequest">{{
                                    $t('title.yes')
                                }}
                            </b-button>
                            <b-button variant="outline-primary" @click="closeModal">{{ $t('title.no') }}</b-button>
                        </div>
                    </div>
                </div>
            </b-form>
        </b-modal>
    </div>
</template>

<script>
const FILTER_FORM = {
    
};

const COLUMN_STATE = (self) => [
    { key: 'actions', label: self.$t('title.actions') },
    { key: 'status', label: self.$t('title.status'), sortable: true },
    { key: 'volunteer', label: self.$t('title.volunteer'), sortable: true },
    { key: 'created_at', label: self.$t('title.createdAt'), sortable: true },
];

export default {
    data() {
        return {
            operation: '',
            listUrl: '/event/requests',
            filters: {
                ...FILTER_FORM
            },
            userRequestId: ''
        };
    },
    methods: {
        handleFilter() {
            this.$refs['emergency-table'].handleFilter(this.filters);
        },
        resetFilter() {
            this.filters = { ...FILTER_FORM };
        },
        handleCloseRequest() {
            this.$emit('close', this.userRequestId);
            this.$refs['emergency-table'].handleRefreshList();
        },
         openModalWithId(id) {
            this.userRequestId = id;
            this.$bvModal.show('event-user-request-close');
        },
        closeModal() {
            this.$bvModal.hide('event-user-request-close');
        },
        refreshTable() {
            console.log("refresh table called...");
            this.$refs['emergency-table'].handleRefreshList();
        }
    },
    computed: {
        columns() {
            return COLUMN_STATE(this);
        }
    }
};
</script>
