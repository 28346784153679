<template>
    <div>
        <b-modal v-model="isOpenModal" id="userDetailModal"  :hide-header="true" :hide-footer="true">
            <div>
                <b-tabs content-class="mt-3">
                    <b-tab :title="$t('title.details')" active>
                        <UserDetail :user-detail="volunteerDetail && volunteerDetail.volunteer"/>
                    </b-tab>
                    <b-tab :title="$t('title.chat')">
                        <ChatDesign />
                    </b-tab>
                </b-tabs>
            </div>
            <div class="modal-footer d-flex mr-2 gap-2">
                <button type="button" class="btn sec-btn col m-0" @click="cancelModal">
                    {{ $t('title.cancel') }}
                </button>
            </div>
        </b-modal>
    </div>
</template>

<script>
import ChatDesign from "@/components/ChatDesign";
import UserDetail from "@/components/UserDetail";

export default {
    data() {
        return {
            isOpenModal: false,
            volunteerDetail: null,
            volunteerRequestId: null
        };
    },
    components: {
        ChatDesign,
        UserDetail
    },
    methods: {
        handleToggleModal(detail, volunteerRequestId) {
            this.isOpenModal = !this.isOpenModal;
            this.volunteerDetail = detail;
            this.volunteerRequestId = volunteerRequestId;
        },
        cancelModal() {
            this.isOpenModal = false;
        }
    }
};
</script>
