<template>
    <div class="forbidden">
        <h1>{{ $t('title.forbiddenTitle') }}</h1>
        <p>{{ $t('title.forbiddenMessage') }}</p>
    </div>
</template>

<script>
export default {
    name: 'ForbiddenPage',
};
</script>

<style scoped>
.forbidden {
    text-align: center;
    margin-top: 50px;
}
</style>
