<template>
  <div class="chat-container">
    <ul class="chat-box chatContainerScroll">
      <li
        v-for="(message, index) in messages"
        :key="index"
        :class="message.isSender ? 'chat-right' : 'chat-left'"
      >
        <div v-if="!message.isSender" class="chat-avatar">
          <b-avatar :size="30"></b-avatar>
          <div class="chat-name">{{ message.name }}</div>
        </div>
        <div class="chat-text">{{ message.text }}</div>
        <div class="chat-hour">
          {{ message.time }}
          <span class="fa fa-check-circle"></span>
        </div>
        <div v-if="message.isSender" class="chat-avatar">
          <b-avatar :size="30"></b-avatar>
          <div class="chat-name">{{ message.name }}</div>
        </div>
      </li>
    </ul>
    <div class="form-group mt-3 mb-0 d-flex gap-2 align-items-end">
      <textarea
        class="form-control"
        rows="3"
        :placeholder="$t('title.typeYourMessageHear')"
        v-model="newMessage"
        @keyup.enter="sendMessage"
      ></textarea>
      <b-button
        variant="primary"
        class="btn sec-btn small-btn btn-primary rounded border-0"
        @click="sendMessage"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          class="bi bi-send"
          viewBox="0 0 16 16"
        >
          <path
            d="M15.854.146a.5.5 0 0 1 .11.54l-5.819 14.547a.75.75 0 0 1-1.329.124l-3.178-4.995L.643 7.184a.75.75 0 0 1 .124-1.33L15.314.037a.5.5 0 0 1 .54.11ZM6.636 10.07l2.761 4.338L14.13 2.576zm6.787-8.201L1.591 6.602l4.339 2.76z"
          />
        </svg>
      </b-button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      messages: [
        {
          name: 'Admin',
          text: this.$t('title.messageQuestion'),
          time: '08:55',
          isSender: false,
        },
        {
          name: 'Sam',
          text: this.$t('title.yep'),
          time: '08:56',
          isSender: true,
        },
      ],
      newMessage: '',
    };
  },
  methods: {
    sendMessage() {
      if (this.newMessage.trim()) {
        this.messages.push({
          name: 'Sam', // Replace with dynamic user name if needed
          text: this.newMessage,
          time: new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }),
          isSender: true,
        });
        this.newMessage = ''; // Clear the input field
      }
    },
  },
};
</script>
