<template>
    <div class="main-block request-management">
        <div class="div col-12 p-0 d-flex align-items-start">
            <h4 class="">{{ $t('title.categories') }}</h4>
        </div>
        <div class="scrollable-block">
            <div class="col-12 row mt-3 m-0 p-0" style="min-height: 90%">
                <crud-table :columns="columns" :list-url="listUrl" ref="category-table" v-on:refresh="resetFilter">
                    <template #cell(author)="{item}">
                        {{ ( item.author || {} ).full_name }}
                    </template>
                    <template #cell(created_at)="{item}">
                        {{ item.created_at ? $global.utcDateToLocalDate(item.created_at) : null }}
                    </template>
                    <template #cell(actions)="{item}">
                        <router-link :to="{name:'adminCategoryForm',params:{id:item.id,operation:'edit'}}"
                                     variant="outline-primary"
                                     class="mr-1">
                            <b-icon icon="pencil-square" class="icon-style"></b-icon>
                        </router-link>
                    </template>
                </crud-table>
            </div>
        </div>
    </div>
</template>

<script>

import { request } from "@/Util/Request";

const FILTER_FORM = {
    roles: null,
    statuses: null
};

const COLUMN_STATE = (self) => [
    { key: 'actions', label: self.$t('title.actions') },
    { key: 'name', label: self.$t('title.name'), sortable: true },
    { key: 'author', label: self.$t('title.author'), sortable: true },
    { key: 'created_at', label: self.$t('title.createdAt'), sortable: true },
];

export default {
    data() {
        return {
            operation: '',
            listUrl: '/admin/categories',
            filters: {
                ...FILTER_FORM
            },
        };
    },
    methods: {
        handleFilter() {
            this.$refs['category-table'].handleFilter(this.filters);
        },
        resetFilter() {
            this.filters = { ...FILTER_FORM };
        },
    },
    computed: {
        columns() {
            return COLUMN_STATE(this);
        }
    }
};
</script>
