import axios from 'axios';
import moment from 'moment-timezone';
import store from '@/store';
import { getAuthUser, getStorage, hasAuthUser, removeStorage } from "./auth";
import SecureLS from "secure-ls";
import serverConfig from "../../server.config.json";

export const DEFAULT_STORAGE = new SecureLS({
    encodingType: 'base64',
    isCompression: false
});

// Set the storage prefix based on the environment
const env = process.env.VUE_APP_ENV || "development";

export const STORAGE_PREFIX = serverConfig.storagePrefix[env];

/**
 * Create an Axios Client with defaults
 */
let axiosInstance = axios.create();

axiosInstance.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
axiosInstance.defaults.headers.common['Content-Type'] = 'application/json';
axiosInstance.defaults.headers.common['Content-Language'] = DEFAULT_STORAGE.get(`${ STORAGE_PREFIX }.locale`) || 'en-US';

export const client = axiosInstance;

/**
 * Request Wrapper with default success/error actions
 */
export const request = function (options) {

    if ( hasAuthUser() && getAuthUser() ) {
        const user = getAuthUser();
        axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${ user.token }`;
        axiosInstance.defaults.headers.common['Content-Timezone'] = moment.tz.guess();
        axiosInstance.defaults.headers.common['Content-Language'] = getStorage('locale') || 'en-US';
    }

    const onSuccess = function (response) {
        return response;
    };

    const onError = async function (error) {
        if ( error.response ) {
            if ( error.response.status === 403 ) {
                removeStorage('auth');
                await store.dispatch('user', null);
                await store.dispatch('setUnAuthorized', true);
                window.location.href = "/forbidden";
                return false;
            }

            // if ( error.response.status === 401 ) {
            //     const user = getAuthUser();
            //     const role = user?.role;
            //     console.log(role,'-----requst.js')
            //     alert('Your session has expired. Please log in again.');
            //     removeStorage('auth');
            //     if ( role !== 'admin' ) {
            //         window.location.href = "/login";
            //     } else {
            //         window.location.href = "/admin/login";
            //     }
            //     return false;
            // }
        } else {
            // Something else happened while setting up the request
            // triggered the error
            // console.error('Error Message:', error.message);
        }

        return Promise.reject(error.response || error.message);
    };

    return axiosInstance(options)
    .then(onSuccess)
    .catch(onError);
};
