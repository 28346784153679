<template>
    <div v-if="$global.hasRole('manager')">
        <div class="map-events d-flex flex-column flex-sm-row" v-if="detail">
            <AzureMap v-if="detail" :item="detail" />
            <SideListVolunteer v-if="volunteers.length" :volunteers="volunteers" @refreshVolunteers="getDetail(eventId)" :eventId="eventId" />
            <div class="map-info bg-blur d-flex gap-3 mx-auto">
                <div class="info d-flex align-items-center gap-1">
                    <span class="pointer">
                        <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="14"
                        height="14"
                        fill="#621E7D"
                        stroke="#621E7D"
                        stroke-width="2"
                        class="bi bi-circle-fill"
                        viewBox="0 0 16 16"
                    >
                        <circle cx="7" cy="7" r="6" />
                      </svg>
                    </span>
                    <span class="text">{{ $t('title.user') }}</span>
                </div>
                <div class="info d-flex align-items-center gap-1">
                    <span class="pointer">
            <svg
            xmlns="http://www.w3.org/2000/svg"
            width="14"
            height="14"
            fill="#fff"
            stroke="#621E7D"
            stroke-width="2"
            class="bi bi-circle-fill"
            viewBox="0 0 16 16"
        >
            <circle cx="7" cy="7" r="6" />
          </svg>
        </span>
                    <span class="text">{{ $t('title.volunteer') }}</span>
                </div>
            </div>
            <SideListUser v-if="users.length" :users="users" @refreshUsers="getDetail(eventId)" :eventId="eventId" class="mt-auto mt-sm-0 ms-sm-auto" />
        </div>
    </div>
</template>
<script>
import SideListVolunteer from '@/components/SideListVolunteer.vue';
import SideListUser from "@/components/SideListUser";
import AzureMap from '@/components/AzureMap.vue';
import { request } from "@/Util/Request";

export default {
    data() {
        return {
            loadMap: false,
            volunteers: [],
            users: [],
            detail: null,
            eventId: null
        };
    },
    components: {
        SideListUser,
        SideListVolunteer,
        AzureMap,
    },
    mounted() {
        if ( this.$route.params && this.$route.params.id ) {
            this.eventId = parseInt(this.$route.params.id);
            this.getDetail(this.$route.params.id);
        }
    },
    methods: {
        async getDetail(id) {
            try {
                const response = await request({
                    method: 'get',
                    url: `/manager/events/${ id }`
                });

                const { data } = response;

                this.volunteers = data.event_volunteer_requests;
                this.users = data.event_user_requests;
                this.detail = data;

            } catch (error) {
                this.notifyError();
            }
        },
    }
};
</script>
<style lang="scss">
header.main-header,
.custom-sidebar .b-sidebar.bg-light {
    z-index: 1;
    background-color: #ffffff5c !important;
}

.special-block {
    overflow: visible;
    box-shadow: none;
}

.map-events {
    @media (max-width: 575.98px) {
        min-height: calc(100vh - 150px);
    }
}
</style>
