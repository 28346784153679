<template>
    <div class="main-block open-volunteering">
        <h4 class="">{{ $t('title.openVolunteering') }}</h4>
        <div class="col-12 text-center" v-if="loader">
            <div class="spinner-border spinner-border-sm" role="status"></div>
        </div>
        <div class="scrollable-block">
            <div class="col-12 row mt-3 m-0 p-0" v-if="user">
                <b-card-group columns>
                    <RequestCard v-for="(card, index) in cards" :key="index" :index="index + 1" :item="card"
                                 :userId="user.id" v-on:refresh="getList" />
                </b-card-group>
            </div>
        </div>
    </div>
</template>

<script>
import RequestCard from '@/components/RequestCard.vue';
import { request } from "@/Util/Request";
import { mapState } from "vuex";

export default {
    components: {
        RequestCard,
    },
    data() {
        return {
            cards: [],
        };
    },
    mounted() {
        this.getList();
    },
    methods: {
        async getList() {
            try {
                const response = await request({
                    method: 'get',
                    url: `/volunteer/application-open-requests`,
                });

                const { data } = response;
                this.cards = data;

            } catch (error) {
            }
        },
    },
    computed: {
        ...mapState(['user', 'loader'])
    }
};
</script>
