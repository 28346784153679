<template>
    <div class="w-100 d-flex mt-3">
        <div class="col-12 text-center" v-if="isGetDetails">
            <div class="spinner-border spinner-border-sm" role="status"></div>
        </div>
        <div class="col-12" v-else>
             <b-alert variant="danger" show v-if="!disabilityId && user.roles.includes('user')">
                <h6 class="m-0">
                    <b-icon icon="exclamation-circle"></b-icon>
                    {{ $t('title.disableDisablityOption') }} 
                </h6>
            </b-alert>
            <b-form @submit.prevent="handleSubmit">
                <b-row>
                    <div class="col-12 col-md-8">
                        <b-row>
                            <div class="col-12 col-md-6">
                                <b-form-group v-if="!disabilityId && user.roles.includes('user')" :label="$t('title.disability')"
                                            label-for="disability">
                                    <treeselect :placeholder="$t('title.select')"
                                                v-model="formFields.disability_id" class="form-input"
                                                :class="formErrors.has('disability_id') ? `error-input` : ``"
                                                :options="dropdowns.disabilities" required />
                                    <b-form-invalid-feedback
                                        :state="!formErrors.has('disability_id')">
                                        {{ formErrors.first('disability_id') }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </div>
                        </b-row>
                        <b-row>
                            <div class="col-12 col-md-6">
                                <label for="full_name">{{ $t('title.fullName') }}</label>
                                <b-form-input name="full_name" v-model="formFields.full_name" @input="resetError"
                                              type="text"></b-form-input>
                                <b-form-invalid-feedback
                                    :state="!formErrors.has('full_name')">
                                    {{ formErrors.first('full_name') }}
                                </b-form-invalid-feedback>
                            </div>
                            <div class="col-12 col-md-6">
                                <label for="email">{{ $t('title.email') }}</label>
                                <b-form-input name="email" v-model="formFields.email" @input="resetError"
                                              type="email"></b-form-input>
                                <b-form-invalid-feedback
                                    :state="!formErrors.has('email')">
                                    {{ formErrors.first('email') }}
                                </b-form-invalid-feedback>
                            </div>
                        </b-row>
                        <b-row class="mt-3">
                            <div class="col-12 col-md-6">
                                <label for="phone">{{ $t('title.phone') }}</label>
                                <vue-tel-input
                                    :class="formErrors.has('phone') ? `error-input` : ``"
                                    v-model="formFields.phone"
                                    v-bind="phone.options"
                                    @input="onInput"
                                    @focus="resetError"
                                    required
                                />
                                <b-form-invalid-feedback
                                    :state="!formErrors.has('phone')">
                                    {{ formErrors.first('phone') }}
                                </b-form-invalid-feedback>
                            </div>
                        </b-row>
                        <b-row class="mt-3">
                            <div class="col-12">
                                <div class="d-flex">
                                    <label for="residential_address">{{ $t('title.residentialAddress') }}</label>
                                    <b-form-radio-group class="ml-2"  v-model="formFields.address_type" name="address_type">
                                        <b-form-radio value="residential">
                                        </b-form-radio>
                                    </b-form-radio-group>
                                </div>
                                <input
                                    type="text"
                                    v-model="formFields.residential_address"
                                    @input="search('residential_address')"
                                    @focus="onFocus('residential_address')"
                                    @blur="handleBlur"
                                    :placeholder="$t('title.searchLocation')"
                                    class="autocomplete-input form-control"
                                />
                                <ul class="autocomplete-list">
                                    <li
                                        v-for="(suggestion, index) in dropdowns.addresses.residential_address"
                                        :key="index"
                                        @click="selectSuggestion(suggestion,'residential_address')"
                                    >
                                        <span v-if="suggestion.id == 'default'">
                                             <b-spinner small variant="primary" type="grow"
                                                        label="Loading..."></b-spinner>
                                        </span>
                                        {{ suggestion.label }}
                                    </li>
                                </ul>
                                <b-form-invalid-feedback
                                    :state="!formErrors.has('residential_address')">
                                    {{ formErrors.first('residential_address') }}
                                </b-form-invalid-feedback>
                            </div>
                            <div class="col-12 mt-3">
                                <div class="d-flex">
                                    <label for="arrival_address">{{ $t('title.arrivalAddress') }}</label>
                                    <b-form-radio-group class="ml-2"  v-model="formFields.address_type" name="address_type">
                                        <b-form-radio value="arrival">
                                        </b-form-radio>
                                    </b-form-radio-group>
                                </div>
                                <input
                                    type="text"
                                    v-model="formFields.arrival_address"
                                    @input="search('arrival_address');resetError"
                                    @focus="onFocus('arrival_address')"
                                    @blur="handleBlur"
                                    :placeholder="$t('title.searchLocation')"
                                    class="autocomplete-input form-control"
                                />
                                <ul class="autocomplete-list">
                                    <li
                                        v-for="(suggestion, index) in dropdowns.addresses.arrival_address"
                                        :key="index"
                                        @click="selectSuggestion(suggestion,'arrival_address')"
                                    >
                                        <span v-if="suggestion.id == 'default'">
                                            <b-spinner small variant="primary" type="grow"
                                                       label="Loading..."></b-spinner>
                                        </span>
                                        {{ suggestion.label }}
                                    </li>
                                </ul>

                                <b-form-invalid-feedback
                                    :state="!formErrors.has('arrival_address')">
                                    {{ formErrors.first('arrival_address') }}
                                </b-form-invalid-feedback>
                            </div>
                            <div class="col-12 mt-3">
                                <label>
                                    {{ $t('title.wayForCommunication') }}
                                </label>
                            </div>
                            <div class="col-3">
                                <b-form-checkbox
                                    id="checkbox-1"
                                    v-model="formFields.is_phone"
                                    name="checkbox-1"
                                    value="1"
                                    unchecked-value="0"
                                >
                                    {{ $t('title.phone') }}
                                </b-form-checkbox>
                                <b-form-invalid-feedback
                                    :state="!formErrors.has('is_phone')">
                                    {{ formErrors.first('is_phone') }}
                                </b-form-invalid-feedback>
                            </div>
                            <div class="col-3">
                                <b-form-checkbox
                                    id="checkbox-2"
                                    v-model="formFields.is_wp"
                                    name="checkbox-2"
                                    value="1"
                                    unchecked-value="0"
                                >
                                    {{ $t('title.whatsApp') }}
                                </b-form-checkbox>
                                <b-form-invalid-feedback
                                    :state="!formErrors.has('is_wp')">
                                    {{ formErrors.first('is_wp') }}
                                </b-form-invalid-feedback>
                            </div>
                        </b-row>
                    </div>
                </b-row>
                <b-row class="mt-3">
                    <b-col sm="12 mb-3">
                        <b-button type="submit" variant="primary" :disabled="isSubmitProfile">{{ $t('title.update') }}</b-button>
                    </b-col>
                </b-row>
            </b-form>
        </div>
    </div>
</template>


<script>
import Error from "@/Util/Error";
import { request } from "@/Util/Request";
import { ASYNC_SEARCH } from '@riophae/vue-treeselect';
import destinationMixin from "@/Util/destinationMixin";
import { reactive } from "vue";
import { mapGetters, mapState } from "vuex";
import { getAuthUser, setStorage } from "@/Util/auth";

const simulateAsyncOperation = fn => {
    setTimeout(fn, 2000);
};

const phone = reactive({
    value: "",
    isBlurred: false,
    inputValue: {
        formatted: "",
        valid: false,
    },
    options: {
        autoFormat: true,
        inputOptions: {
            showDialCode: false,
            showDialCodeInList: true,
        },
        mode: "international",
        validCharactersOnly: true,
    },
});

const FORM_STATE = {
    email: null,
    phone: null,
    full_name: null,
    residential_address: null,
    arrival_address: null,
    disability_id: null,
    is_phone: 0,
    is_wp: 0,
    address_type: 'residential'
};

export default {
    mixins: [destinationMixin],
    data() {
        return {
            formFields: { ...FORM_STATE },
            formErrors: new Error({}),
            address: null,

            options: [],
            isSubmitProfile: false,
            phone: phone,
            dropdowns: {
                disabilities: []
            },
            disabilityId: null,
            isGetDetails: false
        };
    },
    mounted() {
        this.getProfile();
        this.getDropdowns();
    },
    beforeDestroy() {
    },
    methods: {
         async getDropdowns() {
            try {
                const response = await request({
                    method: 'post',
                    url: `/dropdowns/register`
                });

                const { disabilities } = response;
                this.dropdowns.disabilities = disabilities;
            } catch (error) {
            }
        },
        onInput(formattedNumber, input) {
            this.phone.inputValue = input;
            if ( input && input.nationalNumber ) {
                setTimeout(() => {
                    this.formFields.phone = this.$global.displayPhoneNumber(input.nationalNumber);
                }, 0);
            }
        },
        resetError() {
            this.formErrors = new Error({});
        },
        async getProfile() {
            try {
                this.isGetDetails = true;
                const response = await request({
                    method: 'get',
                    url: `/auth/profile`,
                });

                const { data } = response;

                this.formFields = {
                    disability_id: data.disability_id,
                    full_name: data.full_name,
                    email: data.email,
                    phone: data.phone,
                    residential_address: data.residential_address,
                    arrival_address: data.arrival_address,
                    is_phone: data.is_phone,
                    is_wp: data.is_wp,
                    address_type: data.address_type
                };
                this.disabilityId = data.disability_id;
                this.isGetDetails = false;
            } catch (error) {
                this.isGetDetails = false;
                this.notifyError();
            }
        },
        async handleSubmit() {
            this.resetError();
            if ( !this.phone.inputValue.valid ) {
                this.formErrors = new Error({
                    phone: [this.$t('title.enterValidPhoneNumber')]
                });
            } else {

                try {
                    this.isSubmitProfile = true;
                    const response = await request({
                        method: 'post',
                        url: `/auth/profile`,
                        data: {
                            ...this.formFields,
                            phone: ( this.phone.inputValue || {} ).number,
                        }
                    });

                    this.notifySuccessWithMsg(`${ this.$t('title.profile') } ${ this.$t('title.updateSuccessfully') }`);
                    setTimeout(() => {
                        this.isSubmitProfile = false;
                    }, 3000);

                    if(!this.disabilityId && this.formFields.disability_id && this.user.roles.includes('user')) {
                        let updatedUser = this.user;
                        this.disabilityId = this.formFields.disability_id;
                        updatedUser.disability_id = this.formFields.disability_id;
                        await setStorage("auth", JSON.stringify(updatedUser));
                        await this.$store.dispatch("user", updatedUser);
                    }
                } catch (error) {

                    this.isSubmitProfile = false;

                    if ( error.request && error.request.status && error.request.status === 422 ) {
                        this.formErrors = new Error(JSON.parse(error.request.responseText).errors);
                        return false;
                    } else {
                        this.notifyError();
                    }
                }
            }
        },
    },
    computed: {
        ...mapGetters(['user']),
        ...mapState(['loader', 'user'])
    }
};
</script>


