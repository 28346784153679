<template>
    <div class="main-block notification-log">
        <div class="div col-12 p-0 d-flex align-items-start">
            <h4 class="">{{ $t('title.notificationLog') }}</h4>
        </div>
        <div class="scrollable-block">
            <div class="col-12 row mt-3 m-0 p-0">
                <crud-table :columns="columns" :list-url="listUrl" ref="notifications-table" v-on:refresh="resetFilter"
                            :shouldShowSearchBox="shouldShowSearchBox">
                    <template #filter-slot>
                        <b-col lg="4" class="mt-3 mt-md-0">
                            <b-form-group label-for="type-select" class="mb-0">
                                <treeselect :multiple="false" @input="handleFilter" v-model="filters.notification_types"
                                            class="form-input"
                                            :placeholder="$t('title.select')"
                                            :options="notificationTypes" />
                            </b-form-group>
                        </b-col>
                    </template>
                    <template #cell(notification_type)="{item}">
                        {{ ( item.notification_type || {} ).name }}
                    </template>
                    <template #cell(send_to)="{item}">
                        <div dir="ltr">
                            {{ $global.displayPhoneNumber(item.send_to) }}
                        </div>
                    </template>
                    <template #cell(action_type)="{item}">
                        {{ item.action_type.toUpperCase() }}
                    </template>
                    <template #cell(message)="{item}">
                        <div v-html="item.message"></div>
                    </template>
                </crud-table>
            </div>
        </div>
    </div>
</template>

<script>
import { request } from "@/Util/Request";
import { mapState } from "vuex";

const FILTER_FORM = {};

const COLUMN_STATE = (self) => [
    { key: 'notification_type', label: self.$t('title.type') },
    { key: 'send_to', label: self.$t('title.sentTo') },
    { key: 'date', label: self.$t('title.date') },
    { key: 'time', label: self.$t('title.time') },
    { key: 'action_type', label: self.$t('title.channel') },
    { key: 'message', label: self.$t('title.content') },
];

export default {
    data() {
        return {
            operation: '',
            listUrl: '/notifications',
            filters: {
                ...FILTER_FORM
            },
            notificationTypes: [],
            shouldShowSearchBox: false
        };
    },
    mounted() {
        this.getNotificationTypes();
    },
    methods: {
        handleFilter() {
            this.$refs['notifications-table'].handleFilter(this.filters);
        },
        resetFilter() {
            this.filters = { ...FILTER_FORM };
        },
        async getNotificationTypes() {
            try {
                const response = await request({
                    method: 'get',
                    url: `/notification-types`,
                });

                const { data } = response;

                this.notificationTypes = [
                    { id: '', label: this.$t('title.all') },
                    ...data
                ];
            } catch (error) {
            }
        },
    },
    computed: {
        columns() {
            return COLUMN_STATE(this);
        }
    }
};
</script>
