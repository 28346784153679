<template>
    <div>
        <b-container fluid>
            <b-row>
                <b-col sm="12" xs="12" class="mb-5">
                    <map-counter />
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>
import mapCounter from "@/view/auth/dashboard/admin/tables/mapCounter";

export default {
    components: {
        mapCounter
    },
    data() {
        return {};
    }
};
</script>
