<template>
    <div>
        <b-container fluid>
            <b-row>
                <b-col sm="12" xs="12">
                    <pending-application />
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>
import PendingApplication from "@/view/auth/dashboard/admin/tables/pendingApplication";

export default {
    components: {
        PendingApplication,
    },
    data() {
        return {};
    }
};
</script>
