<template>
    <div
        id="myMap"
        style="
      border: 0;
      position: fixed;
      left: 0px;
      right: 0px;
      top: 0px;
      bottom: 0px;
      width: 100%;
      height: 100%;
    "
    ></div>
</template>

<script>
import * as atlas from 'azure-maps-control';

export default {
    props: ['item'],
    name: 'AzureMap',
    data() {
        return {
            map: null,
            datasource: null,
            popup: null,
            searchQuery: '',
            searchResults: [],
            minSearchInputLength: 3,
            keyStrokeDelay: 150,
            searchTimeout: null,
            userPoints: [],
            volunteerPoints: []
        };
    },
    mounted() {
        this.initializeMap();
    },
    methods: {
        initializeMap() {
            this.map = new atlas.Map('myMap', {
                center: [parseFloat(this.item.location.lng), parseFloat(this.item.location.lt)],
                zoom: 14,
                view: 'Auto',
                authOptions: {
                    authType: 'subscriptionKey',
                    subscriptionKey:
                        '1hINXj3kC6I4HcLTx8BoOuQEmFIzqHTFU4jyw757ux0SrUN8UPq9JQQJ99ALACi5Ypz5fxBSAAAgAZMP2Lh0',
                },
            });

            this.map.events.add('ready', this.onMapReady);
        },

        onMapReady() {
            this.datasource = new atlas.source.DataSource();
            this.map.sources.add(this.datasource);

            var center = [parseFloat(this.item.location.lng), parseFloat(this.item.location.lt)];
            var radius = parseFloat(this.item.radius) * 1000; //default radius is in meter form and we taking km form so we have multiply by 1000
            var numPoints = 64;
            var coordinates = [];

            for (let i = 0; i < numPoints; i++) {
                let angle = ( i / numPoints ) * ( Math.PI * 2 );
                let x =
                    center[0] + ( ( radius / 111325 ) * Math.cos(angle) ) / Math.cos(( center[1] * Math.PI ) / 180);
                let y = center[1] + ( radius / 111325 ) * Math.sin(angle);
                coordinates.push([x, y]);
            }
            coordinates.push(coordinates[0]);

            this.datasource.add(
                new atlas.data.Feature(new atlas.data.Polygon([coordinates]), {
                    subType: 'Circle',
                })
            );

            this.datasource.add(
                new atlas.data.Feature(new atlas.data.Point(center), {
                    subType: 'CenterPoint',
                    radius: 5,
                })
            );

            this.map.layers.add(
                new atlas.layer.PolygonLayer(this.datasource, null, {
                    filter: ['==', 'subType', 'Circle'],
                    fillColor: 'rgba(98, 30, 125, 0.4)',
                })
            );

            this.map.layers.add(
                new atlas.layer.BubbleLayer(this.datasource, null, {
                    filter: ['==', 'subType', 'CenterPoint'],
                    color: 'rgb(98, 30, 125)',
                    radius: ['get', 'radius'],
                })
            );

            this.popup = new atlas.Popup({
                closeOnMouseLeave: false,
            });

            //getting event volunteer and user points
            this.eventVolunteerPoints();
            this.eventUseroints();

            this.userPoints.forEach(point => {
                const feature = new atlas.data.Feature(new atlas.data.Point(point.coordinates), {
                    subType: 'UserPoint',
                    name: point.name,
                    address: point.address,
                    phone: point.phone,
                    radius: 5,
                });
                this.datasource.add(feature);
            });

            this.volunteerPoints.forEach(point => {
                const feature = new atlas.data.Feature(new atlas.data.Point(point.coordinates), {
                    subType: 'VolunteerGroup',
                    name: point.name,
                    address: point.address,
                    phone: point.phone,
                    radius: 5,
                });
                this.datasource.add(feature);
            });

            const userLayer = new atlas.layer.BubbleLayer(this.datasource, null, {
                filter: ['==', 'subType', 'UserPoint'],
                strokeColor: 'rgb(98, 30, 125)',
                strokeWidth: 2,
                color: 'rgb(98, 30, 125)',
                radius: ['get', 'radius'],
            });

            const volunteerLayer = new atlas.layer.BubbleLayer(this.datasource, null, {
                filter: ['==', 'subType', 'VolunteerGroup'],
                strokeColor: 'rgb(98, 30, 125)',
                strokeWidth: 2,
                color: 'rgb(255, 255, 255)',
                radius: ['get', 'radius'],
            });

            this.map.layers.add(userLayer);
            this.map.layers.add(volunteerLayer);

            this.map.events.add('mouseover', userLayer, e => {
                this.handleHoverEvent(e);
            });

            this.map.events.add('mouseover', volunteerLayer, e => {
                this.handleHoverEvent(e);
            });

            this.map.events.add('mouseout', userLayer, () => {
                this.popup.close();
            });

            this.map.events.add('mouseout', volunteerLayer, () => {
                this.popup.close();
            });
        },

        handleHoverEvent(event) {
            if (event.shapes && event.shapes.length > 0) {
                const shape = event.shapes[0];
                const properties = shape.getProperties();
                const position = shape.getCoordinates();
                if (properties && properties.name && position) {
                    this.popup.setOptions({
                        position: shape.getCoordinates(),
                        pixelOffset: [5, -10],
                        content: `<div class="super-popup">
                <div class="header">
                  <span>${properties.address}</span>
                </div>
                <div class="modal-body">
                  <span>${properties.name}</span><br>
                  <a href="#" class="d-flex gap-1 align-items-center">
                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-telephone" viewBox="0 0 16 16">
                      <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.6 17.6 0 0 0 4.168 6.608 17.6 17.6 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.68.68 0 0 0-.58-.122l-2.19.547a1.75 1.75 0 0 1-1.657-.459L5.482 8.062a1.75 1.75 0 0 1-.46-1.657l.548-2.19a.68.68 0 0 0-.122-.58zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.68.68 0 0 0 .178.643l2.457 2.457a.68.68 0 0 0 .644.178l2.189-.547a1.75 1.75 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.6 18.6 0 0 1-7.01-4.42 18.6 18.6 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877z"/>
                    </svg>
                    ${properties.phone}
                    </a>
                </div>
              </div>`,
                    });
                    try {
                        this.popup.open(this.map);
                    } catch (error) {
                        console.error('Failed to open popup:', error);
                    }

                    setTimeout(() => {
                        const closeButton = document.querySelector('.popup-close');
                        console.log(closeButton);
                        if (closeButton) {
                            closeButton.addEventListener('click', () => {
                                this.popup.remove();
                            });
                        }
                    }, 0);
                }
            }
        },

        eventVolunteerPoints() {
            this.item.event_volunteer_requests.forEach(eventVolunteer => {
                    let address = '';
                    let phone = eventVolunteer.volunteer.phone;

                    let volunteerLatitude = 0;
                    let volunteerLongitude = 0;

                    const residential = eventVolunteer.volunteer.residential_address;
                    const arrival = eventVolunteer.volunteer.arrival_address;

                    // Priority logic: main residential -> main arrival -> fallback residential -> fallback arrival
                    if (residential && residential.is_main === 1) {
                        volunteerLatitude = residential.lt;
                        volunteerLongitude = residential.lng;
                        address = `${residential.street}, ${residential.city}, ${ residential.state }, ${ residential.country}`;
                    } else if (arrival && arrival.is_main === 1) {
                        volunteerLatitude = arrival.lt;
                        volunteerLongitude = arrival.lng;
                        address = `${arrival.street}, ${arrival.city}, ${ arrival.state }, ${ arrival.country}`;
                    } else if (residential) {
                        volunteerLatitude = residential.lt;
                        volunteerLongitude = residential.lng;
                        address = `${residential.street}, ${residential.city}, ${ residential.state }, ${ residential.country}`;
                    } else if (arrival) {
                        volunteerLatitude = arrival.lt;
                        volunteerLongitude = arrival.lng;
                        address = `${arrival.street}, ${arrival.city}, ${ arrival.state }, ${ arrival.country}`;
                    }

                    // Add to volunteer points only if valid latitude and longitude are found
                    if (volunteerLatitude && volunteerLongitude) {
                        this.volunteerPoints.push({ coordinates: [parseFloat(volunteerLongitude), parseFloat(volunteerLatitude)], name: eventVolunteer.volunteer.full_name, address: address, phone: phone  });
                    }
                });
        },

        eventUseroints() {
            this.item.event_user_requests.forEach(eventUser => {
                    let address = '';
                    let phone =  eventUser.user.phone;

                    let userLatitude = 0;
                    let userLongitude = 0;

                    const residential = eventUser.user.residential_address;
                    const arrival = eventUser.user.arrival_address;

                    // Priority logic: main residential -> main arrival -> fallback residential -> fallback arrival
                    if (residential && residential.is_main === 1) {
                        userLatitude = residential.lt;
                        userLongitude = residential.lng;
                        address = `${residential.street}, ${residential.city}, ${ residential.state }, ${ residential.country}`;
                    } else if (arrival && arrival.is_main === 1) {
                        userLatitude = arrival.lt;
                        userLongitude = arrival.lng;
                        address = `${arrival.street}, ${arrival.city}, ${ arrival.state }, ${ arrival.country}`;
                    } else if (residential) {
                        userLatitude = residential.lt;
                        userLongitude = residential.lng;
                        address = `${residential.street}, ${residential.city}, ${ residential.state }, ${ residential.country}`;
                    } else if (arrival) {
                        userLatitude = arrival.lt;
                        userLongitude = arrival.lng;
                        address = `${arrival.street}, ${arrival.city}, ${ arrival.state }, ${ arrival.country}`;
                    }

                    // Add to user points only if valid latitude and longitude are found
                    if (userLatitude && userLongitude) {
                        this.userPoints.push({ coordinates: [parseFloat(userLongitude), parseFloat(userLatitude)], name: eventUser.user.full_name, address: address, phone: phone });
                    }
                });
        },
    },
};
</script>

<style>
myMap {
    border: 0;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
}

.popup-container {
    z-index: 1000;
    position: fixed;
    top: 0px;
    left: 0px;
}

.super-popup {
    display: flex;
    flex-direction: column;
    max-width: 200px;
}
.popup-content-container {
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.super-popup .header {
    background-color: var(--e-global-color-accent);
    border-radius: 10px 10px 0 0;
    padding: 8px;
    color: #fff;
    font-weight: bold;
    font-size: 12px;
    padding-right: 15px;
}
.super-popup .modal-body {
    padding: 8px;
    color: #000;
    font-weight: bold;
    font-size: 12px;
}
.popup-content-container .popup-close {
    position: absolute;
    right: 0px;
    top: 0px;
    background-color: transparent;
    border: 0px;
    color: #fff;
}
.popup-content-container .tooltiptext {
    display: none !important;
    opacity: 0 !important;
}
.popup-container.bottom .popup-arrow {
    width: 10px;
    height: 10px;
    transform: rotate(-45deg);
    background: #fff;
    position: absolute;
    box-shadow: 1px 4px 8px rgba(0, 0, 0, 0.5);
    z-index: -1;
    bottom: -5px;
    left: calc(50% - 10px);
}
</style>
