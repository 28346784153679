<template>
    <b-modal id="modal-info-volunteer" :title="$t('title.info')" 
            centered
            hide-footer>
            <div class="col-12">
                <table class="table">
                    <thead>
                        <tr>
                            <th>{{ $t('title.color') }}</th>
                            <th>{{ $t('title.request') }}</th>
                            <th>{{ $t('title.description') }}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                <span class="pointer">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" stroke-width="2" viewBox="0 0 16 16" class="bi bi-circle-fill" fill="#621E7D" stroke="#621E7D">
                                        <circle cx="7" cy="7" r="6"></circle>
                                    </svg>
                                </span>
                            </td>
                            <td>
                                {{ $t('title.pending') }}
                            </td>    
                            <td>
                                {{ $t('title.volunteerPendingInfo') }}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <span class="pointer">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" stroke-width="2" viewBox="0 0 16 16" class="bi bi-circle-fill" fill="#28a745" stroke="#28a745">
                                        <circle cx="7" cy="7" r="6"></circle>
                                    </svg>
                                </span>
                            </td>
                            <td>
                                {{ $t('title.accepted') }}
                            </td>    
                            <td>
                               {{ $t('title.volunteerAcceptedInfo') }}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <span class="pointer">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" stroke-width="2" viewBox="0 0 16 16" class="bi bi-circle-fill" fill="#17a2b8" stroke="#17a2b8">
                                        <circle cx="7" cy="7" r="6"></circle>
                                    </svg>
                                </span>
                            </td>
                            <td>
                                {{ $t('title.complete') }}
                            </td>    
                            <td>
                               {{ $t('title.volunteerCompletedInfo') }}
                            </td>
                        </tr>
                        <tr>
                            <td>
                            </td>
                            <td>
                                {{ $t('title.reject') }}
                            </td>    
                            <td>
                               {{ $t('title.volunteerRejectInfo') }}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <span class="pointer">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" stroke-width="2" viewBox="0 0 16 16" class="bi bi-circle-fill" fill="#dc3545" stroke="#dc3545">
                                        <circle cx="7" cy="7" r="6"></circle>
                                    </svg>
                                </span>
                            </td>
                            <td>
                                {{ $t('title.rejected') }}
                            </td>    
                            <td>
                                {{ $t('title.volunteerRejectedInfo') }}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <span class="pointer">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" stroke-width="2" viewBox="0 0 16 16" class="bi bi-circle-fill" fill="#ffc107" stroke="#ffc107">
                                        <circle cx="7" cy="7" r="6"></circle>
                                    </svg>
                                </span>
                            </td>
                            <td>
                                {{ $t('title.assigned') }}
                            </td>    
                            <td>
                                {{ $t('title.volunteerAssignedInfo') }}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <span class="pointer">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" stroke-width="2" viewBox="0 0 16 16" class="bi bi-circle-fill" fill="#ffc107" stroke="#ffc107">
                                        <circle cx="7" cy="7" r="6"></circle>
                                    </svg>
                                </span>
                            </td>
                            <td>
                                {{ $t('title.waitingForApproval') }}
                            </td>    
                            <td>
                                {{ $t('title.volunteerWaitingForApproval') }}
                            </td>
                        </tr> 
                        <tr>
                            <td colspan="2">
                               {{ $t('title.acceptDropdown') }}
                            </td>   
                            <td>
                                {{ $t('title.acceptDropdownInfo') }}
                            </td>
                        </tr>
                        <tr>
                            <td colspan="2">
                              {{ $t('title.rejectDropdown') }}
                            </td>    
                            <td>
                                {{ $t('title.rejectDropdownInfo') }}
                            </td>
                        </tr>   
                    </tbody>
                </table>
            </div>
        </b-modal>
</template>

<script>
export default {

}
</script>

