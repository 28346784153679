<template>
    <div>
        <b-card no-body>
            <b-tabs v-model="tabIndex" card>
                <b-tab :title="$t('title.manageSingleHelpRequest')" @click="tabIndex = 0" lazy>
                    <single-help-request-basket />
                </b-tab>
                <b-tab :title="$t('title.manageEventHelpRequest')" @click="tabIndex = 1" lazy>
                    <event-help-request-basket />
                </b-tab>
            </b-tabs>
        </b-card>
    </div>
</template>

<script>
import EventHelpRequestBasket from './eventHelpRequestBasket';
import SingleHelpRequestBasket from './singleHelpRequestBasket';


export default {
    components: { SingleHelpRequestBasket, EventHelpRequestBasket },
    data() {
        return {
            tabIndex: 0
        };
    }
};
</script>
