<template>
    <div class="w-100 mt-3">
        <div class="col-12 m-auto">
            <h6>{{ $t('title.submitYourQueryHere') }}</h6>
            <b-form @submit.prevent="handleSubmit" class="mt-3">
                <b-row>
                    <div class="col-12 col-md-8">
                        <b-row>
                            <div class="col-12 col-md-12">
                                <label for="subject">{{ $t('title.subject') }}</label>
                                <b-form-input @input="resetError" name="subject" v-model="formFields.subject"
                                              type="text"></b-form-input>
                                <b-form-invalid-feedback
                                    :state="!formErrors.has('subject')">
                                    {{ formErrors.first('subject') }}
                                </b-form-invalid-feedback>
                            </div>
                            <div class="col-12 col-md-12 mt-2">
                                <label for="message">{{ $t('title.message') }}</label>
                                <b-form-textarea
                                    @input="resetError"
                                    id="textarea"
                                    v-model="formFields.message"
                                    rows="3" max-rows="6"></b-form-textarea>
                                <b-form-invalid-feedback
                                    :state="!formErrors.has('message')">
                                    {{ formErrors.first('message') }}
                                </b-form-invalid-feedback>
                            </div>
                        </b-row>
                    </div>
                </b-row>
                <b-row class="mt-3">
                    <div class="col-12 col-md-8">
                        <hr />
                        <b-button variant="primary" type="submit" :disabled="loader">
                            <div class="spinner-border spinner-border-sm" role="status" v-if="loader"></div>
                            {{ $t('title.submit') }}
                        </b-button>
                    </div>
                </b-row>
            </b-form>
        </div>
    </div>
</template>

<script>
import Error from "@/Util/Error";
import { request } from "@/Util/Request";

const FORM_STATE = {
    message: null,
    subject: null
};

export default {
    data() {
        return {
            formFields: { ...FORM_STATE },
            formErrors: new Error({}),
            loader: false
        };
    },
    methods: {
        async handleSubmit() {
            this.resetError();

            try {
                this.loader = true;
                const response = await request({
                    method: 'post',
                    url: `/contacts`,
                    data: this.formFields
                });

                this.notifySuccessWithMsg(`${ this.$t('title.query') } ${ this.$t('title.submitSuccessfully') }`);

                setTimeout(() => {
                    this.loader = false;
                }, 3000);

                this.formFields = { ...FORM_STATE };
            } catch (error) {

                this.loader = false;

                if ( error.request && error.request.status && error.request.status === 422 ) {
                    this.formErrors = new Error(JSON.parse(error.request.responseText).errors);
                    return false;
                } else {
                    this.notifyError();
                }
            }
        },
        resetError() {
            this.formErrors = new Error({});
        }
    }
};
</script>
