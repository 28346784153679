<template>
    <div>
        <div class="filter-container ms-auto my-2 col-12 z-index-10000">
            <div class="d-flex flex-wrap gap-2">
                <div>
                    <label for="fromDate">{{ $t('title.fromDate') }}:</label>
                    <datetime
                        type="date"
                        input-id="from_date"
                        name="from_date"
                        v-model="filters.from_date"
                        :auto="true"
                        :format="{year: 'numeric', month: 'numeric',day: 'numeric'}"
                        class="vdatetime"
                    ></datetime>
                </div>
                <div>
                    <label for="toDate">{{ $t('title.toDate') }}:</label>
                    <datetime
                        type="date"
                        input-id="to_date"
                        name="to_date"
                        v-model="filters.to_date"
                        :auto="true"
                        :min-datetime="new Date().toISOString()"
                        :format="{year: 'numeric', month: 'numeric',day: 'numeric'}"
                        class="vdatetime"
                    ></datetime>
                </div>
                <div class="align-self-end">
                    <b-button size="sm" variant="primary" @click="getDetail">{{ $t('title.submit') }}</b-button>
                </div>
            </div>


        </div>
        <div class="map-events d-flex flex-column flex-sm-row col-12 gap-2">
            <CounterBlock :count="total_help_requests" :label="$t('title.requests')" />
            <div
                class="counter-block bg-blur p-3 d-flex flex-column justify-content-center align-items-center col"
            >
                <!--                <div class="col d-flex">-->
                <!--                    <div>{{ $t('title.totalBuddies') }}:</div>-->
                <!--                    <div class="ms-auto">{{ ( volunteer_card || {} ).buddy_count }}</div>-->
                <!--                </div>-->
                <div class="col d-flex">
                    <div>{{ $t('title.totalActivities') }}:</div>
                    <div class="ms-auto">{{ ( volunteer_card || {} ).activities_count }}</div>
                </div>
                <div class="col d-flex">
                    <div>
                        <router-link
                            v-if="volunteer_points.length > 0"
                            :to="{
                                name: 'AdminUserManagement',
                                query: { user_ids: volunteer_ids }
                            }"
                        >
                            {{ $t('title.volunteers') }}:
                        </router-link>
                        <span v-else>{{ $t('title.volunteers') }}:</span>
                    </div>
                    <div class="ms-auto">
                        <router-link
                            v-if="volunteer_points.length > 0"
                            :to="{
                                name: 'AdminUserManagement',
                                query: { user_ids: volunteer_ids }
                            }"
                        >
                            {{ volunteer_points.length }}
                        </router-link>
                        <span v-else>0</span>
                    </div>
                </div>
            </div>
            <div
                class="counter-block bg-blur p-3 d-flex flex-column justify-content-center align-items-center col"
            >
                <!--                <div class="col d-flex">-->
                <!--                    <div>{{ $t('title.totalBuddies') }}:</div>-->
                <!--                    <div class="ms-auto">{{ ( user_card || {} ).buddy_count }}</div>-->
                <!--                </div>-->
                <!--                <div class="col d-flex">-->
                <!--                    <div>{{ $t('title.totalRequests') }}:</div>-->
                <!--                    <div class="ms-auto">{{ user_card?.help_request_count?.count }}</div>-->
                <!--                </div>-->
                <div class="col d-flex">
                    <div>
                        <router-link
                            v-if="user_points.length > 0"
                            :to="{
                                name: 'AdminUserManagement',
                                query: { user_ids: user_ids }
                            }"
                        >
                            {{ $t('title.personWithDisabilities') }}:
                        </router-link>
                        <span v-else>{{ $t('title.personWithDisabilities') }}:</span>
                    </div>
                    <div class="ms-auto">
                        <router-link
                            v-if="user_points.length > 0"
                            :to="{
                                name: 'AdminUserManagement',
                                query: { user_ids: user_ids }
                            }"
                        >
                            {{ user_points.length }}
                        </router-link>
                        <span v-else>{{ user_points.length }}</span>
                    </div>
                </div>
            </div>
            <CounterBlock :count="total_register_volunteers" :label="$t('title.volunteerJoined')" />
            <CounterBlock :count="total_buddies" :label="$t('title.buddies')" />
        </div>
        <div>
            <div class="map-info bg-blur d-flex gap-3 mx-auto mt-3 z-index-100">
                <div class="info d-flex align-items-center gap-1">
                    <span class="pointer">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="14"
                            height="14"
                            fill="#F01818"
                            stroke="#F01818"
                            stroke-width="2"
                            class="bi bi-circle-fill"
                            viewBox="0 0 16 16"
                        >
                        <circle cx="7" cy="7" r="6" />
                      </svg>
                    </span>
                    <span class="text">{{ $t('title.user') }}</span>
                </div>
                <div class="info d-flex align-items-center gap-1">
                    <span class="pointer">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="14"
                            height="14"
                            fill="#1EF750"
                            stroke="#621E7D"
                            stroke-width="2"
                            class="bi bi-circle-fill"
                            viewBox="0 0 16 16"
                        >
                        <circle cx="7" cy="7" r="6" />
                      </svg>
                    </span>
                    <span class="text">{{ $t('title.volunteer') }}</span>
                </div>
            </div>
            <AdminDashboardAzureMap ref="adminAzureMap"
                                    v-if="(volunteer_points && volunteer_points.length > 0) || (user_points && user_points.length > 0)"
                                    :volunteerPoints="volunteer_points" :userPoints="user_points" />
        </div>
    </div>
</template>

<script>
import CounterBlock from '@/components/CounterBlock.vue';
import { request } from "@/Util/Request";
import moment from "moment-timezone";
import AdminDashboardAzureMap from "@/components/AdminDashboardAzureMap";

export default {
    components: {
        CounterBlock,
        AdminDashboardAzureMap
    },
    data() {
        return {
            filters: {
                from_date: null,
                to_date: null
            },
            total_help_requests: 0,
            total_register_volunteers: 0,
            volunteer_card: {
                buddy_count: 0,
                activities_count: 0
            },
            user_card: {
                buddy_count: 0,
                help_request_count: {},
                user_ids: []
            },
            volunteer_points: [],
            user_points: [],
            user_ids: null,
            volunteer_ids: null,
            total_buddies: 0
        };
    },
    mounted() {
        this.setDefaultDates();
    },
    methods: {
        setDefaultDates() {
            this.filters.from_date = moment().subtract(30, 'days').format("YYYY-MM-DD");
            this.filters.to_date = moment().format("YYYY-MM-DD");
            this.getDetail();
        },
        async getDetail() {
            try {
                const response = await request({
                    method: 'get',
                    params: {
                        from_date: this.filters.from_date,
                        to_date: this.filters.to_date,
                    },
                    url: `/admin/dashboard/count/box`,
                });

                const { data } = response;
                const {
                    total_buddies,
                    total_help_requests,
                    total_register_volunteers,
                    volunteer_card,
                    user_card
                } = data.count;

                this.total_help_requests = ( total_help_requests || {} ).count;
                this.total_register_volunteers = total_register_volunteers;
                this.volunteer_card = volunteer_card;
                this.user_card = user_card;
                this.volunteer_points = volunteer_card.pointer;
                this.user_points = user_card.help_request_count.pointer;
                this.user_ids = user_card.help_request_count.user_ids.join(',');
                this.volunteer_ids = volunteer_card.user_ids.join(',');
                this.total_buddies = total_buddies;

            } catch (error) {
                this.notifyError();
            }
        },
    }
};
</script>
