<template>
    <div>
        <AdminDashboard v-if="$global.hasRole('admin')" />
        <UserDashboard v-if="$global.hasRole('user')" />
        <ManagerDashboard v-if="$global.hasRole('manager')" />
    </div>
</template>

<script>
import { mapState } from "vuex";
import AdminDashboard from "./admin";
import UserDashboard from "./user";
import ManagerDashboard from "./manager";

export default {
    components: {
        AdminDashboard,
        UserDashboard,
        ManagerDashboard
    },
    data() {
        return {};
    },
    mounted() {
        if ( this.isUnAuthorized ) {
            this.router.push({ to: 'Dashboard' });
        }
    },

    computed: {
        ...mapState(['user', 'isUnAuthorized', 'loader'])
    }
};
</script>
