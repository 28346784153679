<template>
    <div class="login w-100 h-100 d-flex">
        <section class="bg-blur login auth col-12 mx-auto align-self-md-center col-md-7 col-xl-5 col-xxl-4">
            <div class="half-design row">
                <div class="col-12 form-block">
                    <router-link :to="{'name' : 'UserNewHelpRequest'}">
                        <div class="logo col-auto p-0">
                            <img src="@/assets/logo_full.svg" alt="logo" height="70px" class="mx-auto" />
                        </div>
                    </router-link>
                    <p class="text-center mt-3">
                        {{ $t('title.welcomeMessage') }}
                    </p>

                    <div class="col-12 col-md-9 mx-auto">
                        <div class="mb-3 text-center" v-if="!submitted">
                            <label for="Phone" :class="computedState"
                            >{{ $t('title.enterPhoneNumber') }}</label
                            >
                            <vue-tel-input
                                ref="telInput"
                                :class="formErrors.has('phone') ? `error-input` : ``"
                                v-model="formFields.phone"
                                mode="national"
                                v-bind="phone.options"
                                :autoFormat="false"
                                validCharactersOnly
                                @focus="resetError"
                                @input="onInput"
                                @keydown.native="checkEnterToSendOtp"
                            />
                            <b-form-invalid-feedback
                                class="text-left"
                                :state="!formErrors.has('phone')">
                                {{ formErrors.first('phone') }}
                            </b-form-invalid-feedback>
                        </div>
                        <div v-else class="mb-3 text-center" dir="ltr">
                            <label>{{ $t('title.enterVerificationCode') }}</label>
                            <div class="d-flex gap-3 text-center">
                                <b-form-input
                                    type="text"
                                    class="form-control base-input text-code"
                                    :class="formErrors.has('otp') ? `error-input` : ``"
                                    v-model="formFields.otp.code1"
                                    :state="otpVerified"
                                    @input.native="e => handleInput('code1', e)"
                                    @keydown.native="e => handleKeyDown('code1', e)"
                                    @focus.native="removeError()"
                                    maxlength="1"
                                />
                                <b-form-input
                                    type="text"
                                    class="form-control base-input text-code"
                                    :class="formErrors.has('otp') ? `error-input` : ``"
                                    v-model="formFields.otp.code2"
                                    :state="otpVerified"
                                    @input.native="e => handleInput('code2', e)"
                                    @keydown.native="e => handleKeyDown('code2', e)"
                                    @focus.native="removeError()"
                                    maxlength="1"
                                />
                                <b-form-input
                                    type="text"
                                    class="form-control base-input text-code"
                                    :class="formErrors.has('otp') ? `error-input` : ``"
                                    v-model="formFields.otp.code3"
                                    :state="otpVerified"
                                    @input.native="e => handleInput('code3', e)"
                                    @keydown.native="e => handleKeyDown('code3', e)"
                                    @focus.native="removeError()"
                                    maxlength="1"
                                />
                                <b-form-input
                                    type="text"
                                    class="form-control base-input text-code"
                                    :class="formErrors.has('otp') ? `error-input` : ``"
                                    v-model="formFields.otp.code4"
                                    :state="otpVerified"
                                    @input.native="e => handleInput('code4', e)"
                                    @keydown.native="e => handleKeyDown('code4', e)"
                                    @focus.native="removeError()"
                                    maxlength="1"
                                />
                            </div>
                            <b-form-invalid-feedback
                                :state="!formErrors.has('otp')">
                                {{ formErrors.first('otp') }}
                            </b-form-invalid-feedback>
                        </div>
                        <b-button type="submit" variant="primary" :disabled="isSubmitLogin" class="mb-3 w-100"
                                  @click="submitted ? onSubmit() : sendOtp()" ref="submitBtn">
                            <div class="spinner-border spinner-border-sm text-light" role="status" v-if="loader"></div>
                            {{ submitted ? $t('title.verifyCode') : $t('title.getCode') }}
                        </b-button>
                        <p class="text-ship-grey text-center mt-1">
                            {{ $t('title.newToPurpleVest') }}
                            <router-link :to="{name:'Registration'}" class="text-main text-decoration-none">
                                {{ $t('title.signUp') }}
                            </router-link>
                        </p>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import { request } from "@/Util/Request";
import Error from "@/Util/Error";
import commonMixin from "@/Util/commonMixin";
import { reactive } from "vue";

const phone = reactive({
    value: "",
    isBlurred: false,
    inputValue: {
        formatted: "",
        valid: false,
    },
    options: {
        autoFormat: true,
        inputOptions: {
            showDialCode: false,
            showDialCodeInList: true,
        },
        mode: "international",
        validCharactersOnly: true,
    },
});

const FORM_STATE = {
    phone: null,
    otp: {
        code1: null,
        code2: null,
        code3: null,
        code4: null,
    },
    number: null
};


export default {
    mixins: [commonMixin],
    data() {
        return {
            isValidP: false,
            submitted: false,
            showError: false,
            otpVerified: null,
            inputOptions: {
                placeholder: 'Enter your phone number',
                autofocus: true,
            },
            formFields: { ...FORM_STATE },
            formErrors: new Error({}),
            number: null,
            country: null,
            phone: phone,
            isSubmitLogin: false
        };
    },
    mounted() {
        this.checkIpStatus();
    },
    computed: {
        computedState() {
            if ( !this.showError ) {
                return 'no-result';
            }
            return this.isValidP ? 'correct' : 'wrong-number';
        },
        ...mapGetters(['user', 'loader'])
    },
    methods: {
        onInput(formattedNumber, input) {
            this.phone.inputValue = input;
            if ( input && input.nationalNumber ) {
                setTimeout(() => {
                    this.formFields.phone = input.nationalNumber;
                }, 0);
            }
        },
        resetError() {
            this.formErrors = new Error({});
        },
        async checkEnterToSendOtp(event) {
            if ( event.keyCode == 13 || event.key === "Enter" ) {
                this.sendOtp();
            }
        },
        async sendOtp() {
            if ( !this.phone.inputValue.valid ) {
                this.formErrors = new Error({
                    phone: [this.$t('title.enterValidPhoneNumber')]
                });
            } else {
                try {
                    this.isSubmitLogin = true;
                    const response = await request({
                        method: 'post',
                        url: `/auth/login`,
                        data: {
                            phone: ( this.phone.inputValue || {} ).number
                        }
                    });

                    this.submitted = true;
                    _.map(this.formFields.otp, (value, key) => {
                        this.formFields.otp[key] = null;
                    });
                    this.notifySuccessWithMsg(this.$t('title.otpSentSuccessMsg'));
                    this.isSubmitLogin = false;
                } catch (error) {
                    this.isSubmitLogin = false;
                    if ( error.request && error.request.status && error.request.status === 422 ) {
                        this.formErrors = new Error(JSON.parse(error.request.responseText).errors);
                        return false;
                    } else {
                        this.notifyError();
                    }
                }
            }
        },
        async onSubmit() {
            try {
                this.isSubmitLogin = true;
                const response = await request({
                    method: 'post',
                    url: `/auth/verify-otp`,
                    data: {
                        phone: ( this.phone.inputValue || {} ).number,
                        otp: _.map(this.formFields.otp).join('')
                    }
                });

                const { data } = response;

                await this.redirectingUserPage(data);

                this.notifySuccessWithMsg(this.$t('title.successLogin'));
                this.isSubmitLogin = false;
            } catch (error) {
                this.isSubmitLogin = false;
                if ( error.request && error.request.status && error.request.status === 422 ) {
                    this.formErrors = new Error(JSON.parse(error.request.responseText).errors);
                    return false;
                } else {
                    this.notifyError();
                }
            }
        },
        handleInput(codeField, event) {
            const target = event.target;

            if ( !target ) {
                return;
            }
            let value = target.value;

            if ( !/^\d$/.test(value) ) {
                this.formFields.otp[codeField] = '';
                return;
            }

            this.formFields.otp[codeField] = value;

            const nextInput = target.nextElementSibling;
            if ( nextInput && nextInput.tagName === 'INPUT' ) {
                nextInput.focus();
            }
        },
        handleKeyDown(codeField, event) {
            const target = event.target;
            if ( event.key == "Enter" || event.keyCode == 13 ) {
                this.$refs.submitBtn.focus();
                this.$refs.submitBtn.click();
                return;
            }
            // Handle backspace to focus previous field if empty
            if ( event.key === 'Backspace' && !this.formFields.otp[codeField] ) {
                const previousInput = target.previousElementSibling;
                if ( previousInput && previousInput.tagName === 'INPUT' ) {
                    previousInput.focus();
                }
            }
            if ( codeField == "code4" && event.target ) {

            }
        },
        async removeError() {
            let removeError = true;
            _.forEach(this.formFields.otp, (otp, key) => {
                if ( otp ) {
                    removeError = false;
                }
            });

            if ( removeError ) {
                this.formErrors = new Error({});
            }
        },
        async checkIpStatus() {
            try {
                const response =  await request({
                    method: 'get',
                    url: `/check-ip-status`,
                });
            } catch (error) {
                if ( error.request && error.request.status && error.request.status === 422 ) {
                    this.formErrors = new Error(JSON.parse(error.request.responseText).errors);
                    return false;
                } else {
                    this.notifyError();
                }
            }
        }
    },
    watch: {
        'formFields.otp': {
            handler(newOtpValues) {
                this.removeError();
            },
            deep: true
        }
    },
};
</script>
