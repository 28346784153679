<template>
    <div class="main-block user-management">
        <div class="div col-12 p-0 d-flex align-items-start">
            <h4 class="">{{ $t('title.userManagement') }}</h4>
        </div>
        <div class="scrollable-block">
            <div class="col-12 row mt-3 m-0 p-0">
                <crud-table :columns="columns" :filters="filters" :list-url="listUrl" ref="users-table" v-on:refresh="resetFilter">
                    <template #filter-slot>
                        <b-col lg="2" class="mt-3 mt-md-0">
                            <b-form-group :label="$t('title.type')" label-for="type-select" class="mb-0">
                                <treeselect :multiple="false" @input="handleFilter" v-model="filters.roles"
                                            :placeholder="$t('title.select')"
                                            class="form-input"
                                            :options="dropdowns.roles" />
                            </b-form-group>
                        </b-col>
                    </template>
                    <template>
                        <b-button :disabled="selectedUsers.length == 0" variant="outline-primary" @click="openSendMessageModal"><b-icon icon="cursor-fill" ></b-icon> {{$t('title.writeMessage')}}</b-button>
                    </template>
                    <template #head(select)>
                        <b-form-checkbox
                            id="checkbox"
                            v-model="selectAll"
                            name="checkbox"
                            @change="selectAllUsers"
                        >
                        </b-form-checkbox>
                    </template>
                    <template #cell(select)="row">
                        <b-form-checkbox
                            v-if="row.item.status == 'Activated'"
                            :id="`checkbox-${row.item.id}`"
                            v-model="selectedUsers"
                            :name="`checkbox-${row.item.id}`"
                            :value="row.item.id"
                        >
                        </b-form-checkbox>
                    </template>
                    <template #cell(roles)="{item}">
                        {{ getRoleNames(item.roles) }}
                    </template>
                    <template #cell(is_wp)="{item}">
                        {{ ( item.is_phone ) ? 'Phone' : '' }}{{ ( item.is_phone && item.is_wp ) ? ',' : '' }}{{ ( item.is_wp ) ? 'WhatsApp' : '' }}
                    </template>
                    <template #cell(disability_id)="{item}">
                        {{ ( item.disability || {} ).name }}
                    </template>
                    <template #cell(phone)="{item}">
                        <div dir="ltr">
                            {{ $global.displayPhoneNumber(item.phone) }}
                        </div>
                    </template>
                    <template #cell(email_verified_at)="{item}">
                        {{ $global.dateFormat(item.email_verified_at) }}
                    </template>
                    <template #cell(status)="{item}">
                        <span :class="(item.status == 'Activated') ? 'text-success' : 'text-danger'"> {{ item.status }} </span>
                    </template>
                    <template #cell(actions)="{item}">
                        <b-button
                            v-if="user?.id !== item.id && item.status == 'Activated'"
                            variant="outline-primary"
                            class="mr-1" @click="assignUserRole(item.id, item.roles)">
                            <b-icon icon="person" class="icon-style"></b-icon>
                        </b-button>
<!--                        <b-button-->
<!--                            v-if="_.filter(item.roles,(i) => i.name == 'user').length && !item.has_admin_role"-->
<!--                            title="become an admin"-->
<!--                            variant="outline-primary"-->
<!--                            class="mr-1" @click="handleBecomeAdmin(item.id)">-->
<!--                            <b-icon icon="person" class="icon-style"></b-icon>-->
<!--                        </b-button>-->
                        <b-button
                            v-if="user?.id !== item.id && !item.deleted_at"
                            variant="outline-primary"
                            class="mr-1" @click="handleDelete(item.id, item.roles)">
                            <b-icon icon="trash" class="icon-style"></b-icon>
                        </b-button>
                    </template>
                </crud-table>
            </div>
        </div>
        <UserDeleteConfirmationModal ref="userDeleteModal" @refreshTable="handleRefreshTable"/>
        <UserAssignRoleModal ref="userAssignRoleModal" @refreshTable="handleRefreshTable"/>
        <SendMessageModal ref="sendMessageModal" @refreshTable="handleRefreshTable"/>
    </div>
</template>

<script>

import { request } from "@/Util/Request";
import { mapState } from "vuex";
import UserDeleteConfirmationModal from "@/view/pages/admin/modal/UserDeleteConfirmationModal";
import UserAssignRoleModal from "@/view/pages/admin/modal/UserAssignRoleModal";
import SendMessageModal from "@/view/pages/admin/users/modal/SendMessageModal";

const FILTER_FORM = {
    roles: null,
    user_ids: null
};

const COLUMN_STATE = (self) => [
    { key: 'select', label: '', sortable: false },
    { key: 'full_name', label: self.$t('title.fullName'), sortable: true },
    { key: 'roles', label: self.$t('title.role'), sortable: false },
    { key: 'is_wp', label: self.$t('title.type'), sortable: true },
    { key: 'phone', label: self.$t('title.phone'), sortable: true },
    { key: 'email', label: self.$t('title.email'), sortable: true },
    { key: 'email_verified_at', label: self.$t('title.registrationDate'), sortable: true },
    { key: 'disability_id', label: self.$t('title.disability'), sortable: true },
    { key: 'residential_address', label: self.$t('title.residentialAddress'), sortable: true },
    { key: 'arrival_address', label: self.$t('title.currentAddress'), sortable: true },
    {
        key: 'is_notification_alert',
        label: self.$t('title.alerts'),
        formatter: (value, key, item) => {
            return value ? 'Yes' : 'No';
        },
        sortable: true,
        sortByFormatted: true,
        filterByFormatted: true,
    },
    { key: 'status', label: self.$t('title.status')},
    { key: 'actions', label: self.$t('title.actions') },
];

export default {
    data() {
        return {
            operation: '',
            listUrl: '/admin/users',
            filters: {
                ...FILTER_FORM
            },
            selectedUsers: [],
            selectAll: false,
            userLists: []
        };
    },
    mounted() {
        this.users();
    },
    created() {
        if (this.$route.query.user_ids) {
            this.filters.user_ids = this.$route.query.user_ids;
        }
    },
    components : {
        UserDeleteConfirmationModal,
        UserAssignRoleModal,
        SendMessageModal
    },
    methods: {
        openSendMessageModal() {
            this.$refs['sendMessageModal'].handleToggleModal(this.selectedUsers);
        },
        selectAllUsers() {
            if (this.selectAll) {
                this.selectedUsers = this.userLists.filter(user => user.status === 'Activated').map(user => user.id) ; // Select all user IDs
            } else {
                this.selectedUsers = []; // Deselect all users
            }
        },
        async roles() {
            try {
                const response = await request({
                    method: 'post',
                    url: `/dropdowns/roles`,
                });

                this.dropdowns.roles  = response.map((role) => ({
                    id: role.label,
                    label: role.label, // Optional transformation of value
                }));

            } catch (error) {
                this.notifyError();
            }
        },
        assignUserRole(id, roles) {
            this.$refs['userAssignRoleModal'].handleToggleModal(id, roles);
        },
        getRoleNames(roles) {
            return roles.map(role => role.name).join(', ');
        },
        handleRefreshTable() {
            this.$refs['users-table'].handleRefreshList();
            this.selectedUsers = [];
            this.selectAll = false;
            this.users();
        },
        handleFilter() {
            this.$refs['users-table'].handleFilter(this.filters);
        },
        resetFilter() {
            this.filters = { ...FILTER_FORM };
        },
        async handleDelete(id, roles) {
            this.$refs['userDeleteModal'].handleToggleModal(id, roles);
        },
        async users() {
            try {
                const response = await request({
                    method: 'get',
                    url: '/admin/users',
                });
                this.userLists = response.data

            } catch (error) {
                this.notifyError();
            }
        },
        async handleBecomeAdmin(id) {
            if ( confirm('Are you want to assign admin role to this user?') ) {
                {
                    try {
                        const response = await request({
                            method: 'post',
                            url: `admin/users/become/admin/${ id }`,

                        });

                        this.notifySuccessWithMsg(`Admin role assigned to user successfully`);
                        this.$refs['users-table'].handleRefreshList();

                    } catch (error) {
                        this.notifyError();
                    }
                }
            }

        },
    },
    computed: {
        dropdowns() {
            return this.roles()
        },
        columns() {
            return COLUMN_STATE(this);
        },
        ...mapState(['user'])
    }
};
</script>
