<template>
    <div>
        <b-modal v-model="isOpenModal" id="sendMessageConfirmationModal"  :hide-header="true"
                 :hide-footer="true">
            <h6>{{$t('title.sendMessageConfirmMsg1')}} <b> {{ (userIds) ? userIds.length : 0}} </b> {{$t('title.sendMessageConfirmMsg2')}}</h6>
            <p class="text-success text-center" v-if="countdown != 0">{{$t('title.waitingMessage1')}} {{ countdown }} {{$t('title.waitingMessage2')}}</p>
            <div class="modal-footer d-flex mr-2 gap-2">
                <button type="button" :disabled="(countdown != 0) || isSubmit"  class="btn main-btn col m-0" @click="handleSubmitRequest">
                    {{ $t('title.okSend') }}
                </button>
                <button type="button" class="btn sec-btn col m-0" @click="cancelModal">
                    {{ $t('title.back') }}
                </button>
            </div>
        </b-modal>
    </div>
</template>

<script>
import {request} from "@/Util/Request";
import Error from "@/Util/Error";
export default {
    data() {
        return {
            isOpenModal: false,
            userIds: null,
            countdown: 20,
            countdownInterval: null,
            isSubmit: false
        };
    },
    methods: {
        async handleToggleModal(userIds) {
            this.isSubmit = false;
            this.startCountdown();
            this.userIds = userIds;
            this.isOpenModal = !this.isOpenModal;
        },
        async handleSubmitRequest() {
            this.isSubmit = true;
            this.isOpenModal = false;
            this.$emit('handleSendMessage');
        },
        cancelModal() {
            this.isOpenModal = false;
            clearInterval(this.countdownInterval);
            this.$emit('openSendMessage', this.userIds);
        },
        startCountdown() {
            this.countdown = 20;
            this.countdownInterval = setInterval(() => {
                if (this.countdown > 0) {
                    this.countdown--;
                } else {
                    clearInterval(this.countdownInterval);
                }
            }, 1000);
        },
    }
};
</script>
