<template>
    <b-modal
            id="event-user-request"
            :title="$t('title.confirmation')"
            :centered="true"
            cancel-variant="outline-primary"
            :ok-title="$t('title.yes')"
            :cancel-title="$t('title.no')"
            :hide-footer="true"
        >
        <b-form>
            <div class="row">
                <div class="col-12">
                    <p v-if="formFields.status == 'reject'">
                        {{ $t('title.areYouSureWantToRejectRequest') }}
                    </p>
                    <p v-else>
                        {{ $t('title.areYouWantToCompleteRequest') }}
                    </p>
                </div>
                <div class="col-12 mb-3" v-if="formFields.status == 'reject'">
                    <label for="system_note">{{ $t('title.systemNotes') }}:</label>
                    <b-form-textarea
                        @input="resetErrors"
                        id="textarea"
                        v-model="formFields.system_note"
                        placeholder="Enter something..."
                        rows="3"
                        max-rows="6"
                    ></b-form-textarea>
                    <p v-if="formErrors && formErrors.has('system_note')" class="text-danger">
                        {{ formErrors.first('system_note') }}
                    </p>
                </div>
                <div class="col-12">
                    <hr />
                </div>
                <div class="col-12 text-right">
                    <div class="btn-group">
                        <b-button variant="primary" :disabled="isModalSubmit" class="mr-2" @click="onSubmit">{{
                                $t('title.yes')
                            }}
                        </b-button>
                        <b-button variant="outline-primary" @click="closeModal">{{ $t('title.no') }}</b-button>
                    </div>
                </div>
            </div>
        </b-form>
    </b-modal>
</template>


<script>

import { request } from "@/Util/Request";
import Error from "@/Util/Error";

const FORM_DATA = {
    status: null,
    id: null,
    system_note: null
};

export default {
    data() {
        return {
            formFields: { ...FORM_DATA },
            formErrors: new Error({}),
            isModalSubmit: false
        }
    },
    methods: {
        async onSubmit() {
            try {
                this.isModalSubmit = true;
                const response = await request({
                    method: 'patch',
                    url: `/volunteer/event-user-requests/${ this.formFields.id }`,
                    data: {
                        status: this.formFields.status,
                        system_note: this.formFields.system_note
                    }
                });

                if ( this.formFields.status == 'reject' ) {
                    this.notifySuccessWithMsg(this.$t('title.rejectRequestSuccessfully'));
                } else {
                    this.notifySuccessWithMsg(this.$t('title.completeRequestSuccessfully'));
                }

                this.isModalSubmit = false;
                
                this.$bvModal.hide('event-user-request');

                this.$emit('refreshVolunteerList');

            } catch (error) {
                this.isModalSubmit = false;
                if ( error.request && error.request.status && error.request.status === 422 ) {
                    this.formErrors = new Error(JSON.parse(error.request.responseText).errors);
                    if(this.formErrors.has('error')) {
                        this.notifyErrorWithMsg(this.formErrors.first('error'));
                    }
                    return false;
                } else {
                    this.notifyError();
                }
            }
        },
        closeModal() {
            this.$bvModal.hide('event-user-request');
            this.resetErrors();
            this.formFields = { ...FORM_DATA };
        },
        handleToggleModal(id, status) {
            this.resetErrors();
            this.formFields = {
                status: status,
                id: id,
                system_note: null
            };

            this.$bvModal.show('event-user-request');
        },
        resetErrors() {
            this.formErrors = new Error({});
        },
    }
}
</script>

