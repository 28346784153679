<!--do not modify this file until not required-->
<template>
    <b-container fluid class="p-0 overflow-hidden">
        <b-row class="row p-0 d-flex flex-wrap align-items-end mb-3">
            <b-col lg="4" v-if="shouldShowSearchBox">
                <b-form-group label-for="filter-input" class="mb-0">
                    <b-input-group>
                        <b-form-input
                            id="filter-input"
                            :disable="loader"
                            v-model="query.search"
                            type="search"
                            :placeholder="`${$t('title.enterToSearch')}`"
                            @keyup.enter="handleSearch"
                        ></b-form-input>
                    </b-input-group>
                </b-form-group>
            </b-col>

            <!-- slot for filter button-->
            <slot name="filter-slot"></slot>

            <b-col lg="2" class="mt-3 mt-md-0" v-if="shouldShowSearchBox">
                <b-button variant="primary" class="col-12 col-md-auto" @click="refreshTableData">{{ $t('title.clear') }}
                </b-button>
            </b-col>
        </b-row>

        <div class="row">
            <div class="col-12 text-center py-2" v-if="loader">
                <i class="fa fa-spin fa-spinner"></i>
            </div>
            <div class="col-4">

            </div>
            <div class="col-8">
                <div class="d-flex gap-2 justify-content-end mb-3">
                    <!-- slot for add new button-->
                    <slot></slot>

                    <button :disabled="loader"
                            class="btn btn-primary" :title="$t('title.refresh')" @click="refreshTableData">
                        <b-icon icon="arrow-clockwise"></b-icon>
                    </button>
                </div>
            </div>

        </div>

        <b-table
            :items="dataSources"
            :fields="columns"
            v-if="columns.length"
            label-sort-asc="↑"
            label-sort-desc="↓"
            label-sort-clear=""
            :empty-text="$t('title.noResultFound')"
            :sort-by.sync="query.orderBy"
            :sort-desc.sync="sortBy"
            stacked="md"
            show-empty
            small
            responsive
            table-class="default-table"
        >
            <slot v-for="slot in Object.keys($slots)" :name="slot" :slot="slot" />
            <template v-for="slot in Object.keys($scopedSlots)" :slot="slot" slot-scope="scope">
                <slot :name="slot" v-bind="scope"></slot>
            </template>
        </b-table>

        <b-row class="p-0 align-items-end">
            <b-col class="my-1 col col-lg-3 ms-md-auto">
                <b-form-group
                    :label="$t('title.perPage')"
                    label-for="per-page-select"
                    label-cols-sm="6"
                    label-align-sm="right"
                    label-size="sm"
                    class="mb-0"
                >
                    <b-form-select
                        id="per-page-select"
                        v-model="query.perPage"
                        :options="pageSelection"
                        :disable="loader"
                    ></b-form-select>
                </b-form-group>
            </b-col>
            <b-col class="my-1 col-auto table-pagination">
                <b-pagination
                    v-if="pagination"
                    v-model="query.page"
                    :total-rows="pagination.total"
                    :per-page="query.perPage"
                    prev-class="previous-arrow"
                    next-class="next-arrow"
                    first-number
                    last-number
                    align="fill"
                    size="md"
                    class="my-0"
                ></b-pagination>
            </b-col>
        </b-row>
        <b-modal v-model="deleteModal" id="deleteModal" :title="$t('title.deleteConfirmation')"
                 :ok-title="$t('title.yes')"
                 @ok="handleDelete">
            <p class="my-4">{{ $t('title.areYouSureWantToDelete') }}</p>
        </b-modal>
    </b-container>
</template>

<script>
import ListingMixin from "@/Util/ListingMixin";
import 'bootstrap-vue/dist/bootstrap-vue.css';
import { request } from "@/Util/Request";
import { mapGetters, mapState } from "vuex";

export default {
    mixins: [ListingMixin],
    data() {
        return {
            modal: null,
            deleteUrl: null,
            deleteModal: false,
            tableName: null,
        };
    },
    methods: {
        async handleDelete() {
            if ( this.deleteUrl ) {
                try {
                    const response = await request({
                        method: 'delete',
                        url: this.deleteUrl,

                    });
                    this.notifySuccessWithMsg(`${ this.tableName } ${ this.$t('title.deletedSuccessMsg') }`);
                    this.handleRefreshList();
                } catch (error) {
                    if ( error.request && error.request.status && error.request.status === 422 ) {
                        this.formErrors = new Error(JSON.parse(error.request.responseText).errors);
                        return false;
                    }
                }
            }
        },
        handleDeleteShow(url, name) {
            this.deleteUrl = url;
            this.deleteModal = true;
            this.tableName = name;
        },
        handleFilter(filter) {
            this.getList(filter);
        }
    },
    computed: {
        ...mapState(['loader', 'user'])
    }
};
</script>

<style>
.b-sidebar-footer {
    padding: 0;
}
</style>
