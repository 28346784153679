<template>
    <div
        class="counter-block bg-blur p-3 d-flex flex-column justify-content-center align-items-center col"
    >
        <h1 class="text-break text-center">{{ count }}</h1>
        <p class="m-0 text-break text-center">{{ label }}</p>
    </div>
</template>
<script>
export default {
    props: {
        count: {
            type: [Number, String],
            required: true,
        },
        label: {
            type: String,
            default: 'items',
        },
    },
};
</script>
