<template>
    <div class="main-block new-help-request">
        <div class="col-12">
            <b-alert variant="warning" show dismissible>
                <h6 class="m-0">
                    <b-icon icon="exclamation-circle"></b-icon>
                    {{ $t('title.disableCategoryOption') }}
                </h6>
                <ol>
                    <li class="my-1" v-for="(category,index) in _.filter(dropdowns.type_options,(i) => i.isDisabled )"
                        :key="index">
                        {{ category?.label }} <span
                        v-if="category.valid"> {{
                            $t('title.validUntil')
                        }} {{ $global.utcDateToLocalDate(category?.valid, 'DD/MMM, YYYY') }}</span>
                    </li>
                </ol>
            </b-alert>
        </div>
        <div class="div col-12 p-0 d-flex flex-wrap align-items-center">
            <h4>{{ $t('title.newHelpRequest') }}</h4>
            <div class="progress-container col-9 col-md-6 row gap-3 m-0 p-0 ms-auto my-4 my-md-0">
                <b-progress
                    :value="progress1"
                    max="100"
                    height="10px"
                    class="col p-0"
                    :class="`active-progress-${activeStep}`"
                >
                </b-progress>
                <b-progress
                    :value="progress2"
                    max="100"
                    height="10px"
                    class="col p-0"
                    :class="`active-progress-${activeStep}`"
                >
                </b-progress>
                <!--                <b-progress-->
                <!--                    :value="progress3"-->
                <!--                    max="100"-->
                <!--                    height="10px"-->
                <!--                    class="col p-0"-->
                <!--                    :class="`active-progress-${activeStep}`"-->
                <!--                >-->
                <!--                </b-progress>-->
            </div>
        </div>
        <div class="scrollable-block">
            <div class="col-12 row mt-3 m-0 p-0">
                <b-form @submit.stop.prevent="onSubmit" class="col-12 col-md-6 mx-auto p-0">
                    <div class="m-auto text-center" v-if="loader">
                        <div class="spinner-border spinner-border-sm" role="status"></div>
                    </div>
                    <div class="mb-3" v-if="!formFields.selectedType">
                        <label for="type">{{ $t('title.chooseHelpType') }}</label>
                        <treeselect :multiple="false" v-model="formFields.category_id" :options="dropdowns.type_options"
                                    :placeholder="$t('title.select')"
                                    id="type"></treeselect>
                        <b-button type="submit" variant="primary" class="mt-3 w-100" :disabled="!formFields.category_id"
                        >{{ $t('title.toTheNextStep') }}
                        </b-button
                        >
                    </div>

                    <div v-else-if="transportation_form" class="mb-3">
                        <h5>{{ $t('title.nextStep') }}: {{
                                ( _.find(dropdowns.type_options, (i) => i.id == formFields.category_id) || {} ).label
                            }}
                            {{ $t('title.details') }}</h5>
                        <div class="mb-3">
                            <label for="rideTo">{{ $t('title.iNeedARideTo') }} <span class="mandatory">*</span></label>
                            <!--                            <b-form-input-->
                            <!--                                v-model="formFields.destination_address"-->
                            <!--                                id="rideTo"-->
                            <!--                                :state="showError ? isValidRideTo : null"-->
                            <!--                            ></b-form-input>-->
                            <input
                                type="text"
                                :class="((showError && !isValidRideTo) || formErrors.has('destination_address'))  ? 'is-invalid' : ''"
                                v-model="formFields.destination_address"
                                @input="search('destination_address');resetError;removeError('destination_address')"
                                @focus="onFocus('destination_address');"
                                @blur="handleBlur"
                                :placeholder="$t('title.searchLocation')"
                                class="autocomplete-input form-control"
                            />
                            <ul class="autocomplete-list">
                                <li
                                    v-for="(suggestion, index) in dropdowns.addresses.destination_address"
                                    :key="index"
                                    @click="selectSuggestion(suggestion,'destination_address')"
                                >
                                        <span v-if="suggestion.id == 'default'">
                                             <b-spinner small variant="primary" type="grow"
                                                        label="Loading..."></b-spinner>
                                        </span>
                                    {{ suggestion.label }}
                                </li>
                            </ul>
                            <p class="text-danger" v-if="formErrors.has('destination_address')">
                                {{ formErrors.first('destination_address') }}
                            </p>
                            <b-form-invalid-feedback v-if="showError && !isValidRideTo">
                                {{ $t('title.destinationAddressLonger') }}
                            </b-form-invalid-feedback>
                        </div>

                        <div class="mb-3">
                            <label for="origin_address">{{ $t('title.iAmFrom') }} <span
                                class="mandatory">*</span></label>
                            <input
                                type="text"
                                :class="((showError && !isValidFromPoint) || formErrors.has('origin_address'))  ? 'is-invalid' : ''"
                                v-model="formFields.origin_address"
                                @input="search('origin_address');resetError;removeError('origin_address')"
                                @focus="onFocus('origin_address')"
                                @blur="handleBlur"
                                :placeholder="$t('title.searchLocation')"
                                class="autocomplete-input form-control"
                            />
                            <ul class="autocomplete-list">
                                <li
                                    v-for="(suggestion, index) in dropdowns.addresses.origin_address"
                                    :key="index"
                                    @click="selectSuggestion(suggestion,'origin_address')"
                                >
                                        <span v-if="suggestion.id == 'default'">
                                             <b-spinner small variant="primary" type="grow"
                                                        label="Loading..."></b-spinner>
                                        </span>
                                    {{ suggestion.label }}
                                </li>
                            </ul>
                            <p class="text-danger" v-if="formErrors.has('origin_address')">
                                {{ formErrors.first('origin_address') }}
                            </p>
                            <b-form-invalid-feedback v-if="showError && !isValidFromPoint">
                                {{ $t('title.originAddressLonger') }}
                            </b-form-invalid-feedback>
                        </div>

                        <div class="mb-3">
                            <label for="arrival_date"
                            >{{ $t('title.wantToBeThereOn') }} <span class="mandatory">*</span></label
                            >
                            <datetime
                                type="date"
                                input-id="arrival_date"
                                name="arrival_date"
                                :input-class="
                  ((showError && !isValidDateTransportation) || formErrors.has('arrival_date'))
                    ? 'form-control is-invalid'
                    : 'form-control'
                "
                                v-model="formFields.arrival_date"
                                :auto="true"
                                @input="dateChangeInput;resetError;removeError('arrival_date')"
                                :min-datetime="new Date().toISOString()"
                                :format="{
                                  year: 'numeric',
                                  month: 'numeric',
                                  day: 'numeric',
                                }"
                            ></datetime>
                            <p class="text-danger" v-if="formErrors.has('arrival_date')">
                                {{ formErrors.first('arrival_date') }}
                            </p>
                            <b-form-invalid-feedback
                                :class="showError && !isValidDateTransportation ? 'd-block' : 'd-none'"
                            >
                                {{ $t('title.selectFutureDate') }}
                            </b-form-invalid-feedback>
                        </div>
                        <div class="mb-3">
                            <label for="arrival_time"
                            >{{ $t('title.at') }} <span class="mandatory">*</span></label
                            >
                            <datetime
                                type="time"
                                input-id="arrival_time"
                                name="arrival_time"
                                @input="resetError;removeError('arrival_time')"
                                :input-class="((showError && !isValidDateTransportation) || formErrors.has('arrival_time')) ? 'form-control is-invalid' : 'form-control'"
                                v-model="formFields.arrival_time"
                                :min-datetime="new Date().toISOString()"
                                :format="{
                                  hour: 'numeric',
                                  minute: '2-digit',
                                }"
                            ></datetime>
                            <p class="text-danger" v-if="formErrors.has('arrival_time')">
                                {{ formErrors.first('arrival_time') }}
                            </p>
                            <b-form-invalid-feedback
                                :class="showError && !isValidDateTransportation ? 'd-block' : 'd-none'"
                            >
                                {{ $t('title.selectFutureTime') }}
                            </b-form-invalid-feedback>
                        </div>

                        <div>
                            <label for="description"
                            >{{ $t('title.requestDetailsDescription') }} <span class="mandatory">*</span></label
                            >
                            <b-form-textarea
                                @input="resetError;removeError('description')"
                                v-model="formFields.description"
                                id="description"
                                rows="3"
                                max-rows="6"
                                :input-class="((showError && !isValidDescription) || formErrors.has('description')) ? 'form-control is-invalid' : 'form-control'"
                                :class="((showError && !isValidDescription) || formErrors.has('description')) ? 'is-invalid' : ''"
                            ></b-form-textarea>
                            <p class="text-danger" v-if="formErrors.has('description')">
                                {{ formErrors.first('description') }}
                            </p>
                            <b-form-invalid-feedback v-if="showError && !isValidDescription">
                                {{ $t('title.descriptionLonger') }}
                            </b-form-invalid-feedback>
                        </div>
                        <b-button :disabled="loader" type="submit" variant="primary" class="mt-3 w-100 mb-3">
                            {{ $t('title.toTheNextStep') }}
                        </b-button>
                        <a href="#" @click.prevent="backStep(0)">{{ $t('title.goBack') }}</a>
                    </div>

                    <div v-else-if="form_2" class="mb-3">
                        <h5>{{ $t('title.nextStep') }}: {{
                                ( _.find(dropdowns.type_options, (i) => i.id == formFields.category_id) || {} ).label
                            }}
                            {{ $t('title.details') }}</h5>
                        <div class="mb-3">
                            <label for="description"
                            >{{ $t('title.requestDetailsDescription') }} <span class="mandatory">*</span></label
                            >
                            <b-form-textarea
                                v-model="formFields.description"
                                id="description"
                                rows="3"
                                max-rows="6"
                                @input="resetError;removeError('description')"
                                :input-class="((showError && !isValidDescription) || formErrors.has('description')) ? 'form-control is-invalid' : 'form-control'"
                                :class="formErrors.has('description') ? 'is-invalid': ''"
                            ></b-form-textarea>
                            <p class="text-danger" v-if="formErrors.has('description')">
                                {{ formErrors.first('description') }}
                            </p>
                            <b-form-invalid-feedback v-if="showError && !isValidDescription">
                                {{ $t('title.descriptionLonger') }}
                            </b-form-invalid-feedback>
                        </div>
                        <div class="mb-3">
                            <label for="expiration_date"
                            >{{ $t('title.untilRequestRelevant') }} <span class="mandatory">*</span></label
                            >
                            <datetime
                                input-id="expiration_date"
                                name="expiration_date"
                                type="date"
                                :input-class="((showError && !isValidexpiration_date) || formErrors.has('expiration_date')) ? 'form-control is-invalid' : 'form-control'"
                                :min-datetime="new Date().toISOString()"
                                v-model="formFields.expiration_date"
                                @input="expirationDateChangeInput;resetError;removeError('expiration_date')"
                                :auto="true"
                                :format="{
                                    year: 'numeric',
                                    month: '2-digit',
                                    day: '2-digit'
                                }"
                            />
                            <p class="text-danger" v-if="formErrors.has('expiration_date')">
                                {{ formErrors.first('expiration_date') }}
                            </p>
                            <b-form-invalid-feedback
                                :class="showError && !isValidexpiration_date ? 'd-block' : 'd-none'"
                            >
                                {{ $t('title.selectFutureDate') }}
                            </b-form-invalid-feedback>
                        </div>

                        <b-button :disabled="loader" type="submit" variant="primary" class="mb-3 w-100">{{
                                $t('title.goToNextStep')
                            }}
                        </b-button>
                         <a href="#" @click.prevent="backStep(0)">{{ $t('title.goBack') }}</a>
                    </div>

                    <!--                    <div v-else class="mb-3">-->
                    <!--                        <h5>Personal {{ $t('title.details }}</h5>-->
                    <!--                        <div class="mb-3">-->
                    <!--                            <label for="full_name">Your full name <span class="mandatory">*</span></label>-->
                    <!--                            <b-form-input-->
                    <!--                                v-model="formFields.full_name"-->
                    <!--                                :state="showError ? isValid : null"-->
                    <!--                                id="full_name"-->
                    <!--                            >-->
                    <!--                            </b-form-input>-->

                    <!--                            <b-form-invalid-feedback v-if="showError && !isValid">-->
                    <!--                                Your full name must be longer than 5 characters.-->
                    <!--                            </b-form-invalid-feedback>-->
                    <!--                        </div>-->
                    <!--                        <div class="mb-3">-->
                    <!--                            <label for="phone" :class="computedState"-->
                    <!--                            >phone <span class="mandatory">*</span></label-->
                    <!--                            >-->

                    <!--                            <vue-tel-input-->
                    <!--                                v-model="formFields.phone"-->
                    <!--                                :required="true"-->
                    <!--                                :state="showError ? isValidphone : null"-->
                    <!--                                id="phone"-->
                    <!--                                :validCharactersOnly="true"-->
                    <!--                                :inputOptions="inputOptions"-->
                    <!--                                @validate="isValidphone"-->
                    <!--                            ></vue-tel-input>-->

                    <!--                            <b-form-invalid-feedback :state="computedState != 'wrong-number'">-->
                    <!--                                Your phone must be longer than 5 characters.-->
                    <!--                            </b-form-invalid-feedback>-->
                    <!--                        </div>-->

                    <!--                        <div class="mb-3">-->
                    <!--                            <label for="comments">Comments:</label>-->
                    <!--                            <b-form-textarea-->
                    <!--                                v-model="formFields.comments"-->
                    <!--                                id="comments"-->
                    <!--                                rows="3"-->
                    <!--                                max-rows="6"-->
                    <!--                            ></b-form-textarea>-->
                    <!--                        </div>-->
                    <!--                        <div class="mb-3">-->
                    <!--                            <label for="residential_address"-->
                    <!--                            >Residential address <span class="mandatory">*</span></label-->
                    <!--                            >-->
                    <!--                            <b-form-input-->
                    <!--                                v-model="formFields.residential_address"-->
                    <!--                                id="residential_address"-->
                    <!--                                :state="showError ? isValidA : null"-->
                    <!--                            >-->
                    <!--                            </b-form-input>-->
                    <!--                            <b-form-invalid-feedback v-if="showError && !isValidA">-->
                    <!--                                Your Residential Address must be longer than 5 characters-->
                    <!--                            </b-form-invalid-feedback>-->
                    <!--                        </div>-->

                    <!--                        <div class="mb-3">-->
                    <!--                            <b-form-group label="You can contact me at">-->
                    <!--                                <b-form-checkbox-group-->
                    <!--                                    v-model="formFields.selected_method"-->
                    <!--                                    :options="options"-->
                    <!--                                    name="selected_method"-->
                    <!--                                ></b-form-checkbox-group>-->
                    <!--                            </b-form-group>-->
                    <!--                        </div>-->
                    <!--                        <b-button type="submit" variant="primary" class="mb-3 w-100"-->
                    <!--                        >Create new help request-->
                    <!--                        </b-button-->
                    <!--                        >-->
                    <!--                    </div>-->
                </b-form>
            </div>
        </div>
    </div>
</template>

<script>
import { request } from "@/Util/Request";
import Error from "@/Util/Error";
import { mapState } from "vuex";
import destinationMixin from "@/Util/destinationMixin";

const FORM_STATE = {
    selectedType: false,
    category_id: null,
    arrival_date: null,
    arrival_time: null,
    expiration_date: null,
    description: '',
    full_name: null,
    phone: null,
    email: null,
    comments: null,
    residential_address: null,
    origin_address: null,
    status: null,
    selected_method: [],
    destination_address: null
};


export default {
    mixins: [destinationMixin],
    data() {
        return {
            // personal_details: false,
            isValidP: false,
            form_2: false,
            transportation_form: false,
            showError: false,
            // dropdowns.type_options: [
            //     { id: '1', label: 'Transportation' },
            //     { id: '2', label: 'Transfer of equipment' },
            //     { id: '3', label: 'Clothing' },
            //     { id: '4', label: 'Baby equipment' },
            //     { id: '5', label: 'Toiletries' },
            //     { id: '6', label: 'Medical equipment' },
            // ],
            dropdowns: {
                type_options: []
            },
            options: [
                { text: 'phone', value: 'phone' },
                { text: 'WhatsApp', value: 'whatsapp' },
            ],
            inputOptions: {
                placeholder: '',
            },
            activeStep: 0,
            progress1: 0,
            progress2: 0,
            progress3: 0,
            formFields: { ...FORM_STATE },
            formErrors: new Error({}),
        };
    },
    computed: {
        isValid() {
            return this.formFields.full_name.length >= 5 && this.formFields.full_name.length <= 255;
        },
        isValidA() {
            return this.formFields.residential_address.length >= 5;
        },
        computedState() {
            if ( !this.showError ) {
                return 'no-result';
            }
            return this.isValidP ? 'correct' : 'wrong-number';
        },
        isValidDateTransportation() {
            return this.formFields.arrival_date ? !( this.formFields.arrival_date.length <= 0 ) : true;
        },
        isValidexpiration_date() {
            return this.formFields.expiration_date ? !( this.formFields.expiration_date.length <= 0 ) : true;
        },
        isValidDescription() {
            return this.formFields.description ? this.formFields.description.length >= 5 : true;
        },
        isValidRideTo() {
            return this.formFields.destination_address ? this.formFields.destination_address.length >= 5 : true;
        },
        isValidFromPoint() {
            return this.formFields.origin_address ? this.formFields.origin_address.length >= 5 : true;
        },
        progress() {
            const totalFields = this.getTotalFields();
            const filledFields = this.getFilledFields();
            return totalFields > 0 ? Math.round(( filledFields / totalFields ) * 100) : 0;
        },
        ...mapState(['locale', 'loader'])
    },
    methods: {
       backStep(stepNo) {
            if ( stepNo == 0 ) {
                this.formFields.selectedType = false;
                this.transportation_form = false;
                this.form_2 = false;
                let categoryId = this.formFields.category_id;
                this.formFields = { ...FORM_STATE };
                this.formFields.category_id = categoryId;
            }
            this.activeStep = stepNo;
        },
        async removeError(key){
            if ( typeof key === `object` ) {
                for (let i = 0; i < key.length; i++) {
                    this.formErrors.remove(key[i]);
                }
            } else {
                this.formErrors.remove(key);
            }
        },
        resetError() {
            this.formErrors = new Error({});
            this.showError = false;
        },
        dateChangeInput(value) {
            this.formFields.arrival_date = value;
        },
        expirationDateChangeInput(value) {
            this.formFields.expiration_date = value;
        },
        async updateProgress() {
            // Wait until the DOM is fully updated

            // Trigger reactivity for progress calculation
            this.progress;
        },

        getTotalFields() {
            if ( !this.formFields.selectedType ) {
                return 1; // Step 1: Only 1 required field (type selection)
            } else if ( this.transportation_form ) {
                return 4; // Step 2: 4 fields for transportation type + 5 personal details fields
            } else if ( this.form_2 ) {
                return 2; // Step 2: 2 fields for other types + 5 personal details fields
            }
            // else if ( this.personal_details ) {
            //     return 5;
            // }
            return 0;
        },

        getFilledFields() {
            let filledCount = 0;

            // Step 1: Type selection
            if ( !this.formFields.selectedType && this.formFields.category_id ) {
                filledCount += 1;
                this.activeStep = 1;
            }

            // Step 2: Transportation form
            if ( this.transportation_form ) {
                filledCount += this.formFields.destination_address ? 1 : 0;
                filledCount += this.formFields.origin_address ? 1 : 0;
                filledCount += this.formFields.arrival_date ? 1 : 0;
                filledCount += this.formFields.description ? 1 : 0;

                this.activeStep = 2;
            }

            // Step 2: Form 2 for other types
            if ( this.form_2 ) {
                filledCount += this.formFields.expiration_date ? 1 : 0;
                filledCount += this.formFields.description ? 1 : 0;
                this.activeStep = 2;
            }

            // Step 3: Personal details
            // if ( this.personal_details ) {
            //     if ( this.formFields.full_name ) {
            //         filledCount++;
            //     }
            //     if ( this.formFields.phone ) {
            //         filledCount++;
            //     }
            //     if ( this.formFields.comments ) {
            //         filledCount++;
            //     }
            //     if ( this.formFields.residential_address ) {
            //         filledCount++;
            //     }
            //     if ( this.formFields.selected_method.length > 0 ) {
            //         filledCount++;
            //     }
            //     this.activeStep = 3;
            // }

            return filledCount;
        },
        isValidphone(object) {
            if ( object.valid !== undefined ) {
                this.isValidP = object.valid; // Ensure it's consistent
                return this.isValidP;
            }
            return false;
        },
        async onSubmit() {
            if ( !this.formFields.selectedType && this.formFields.category_id ) {
                this.formFields.selectedType = true;
                if ( ( _.find(this.dropdowns.type_options, (i) => i.id == this.formFields.category_id) || {} ).type == '1' ) {
                    this.transportation_form = true;
                } else {
                    this.form_2 = true;
                }
            } else if ( this.transportation_form ) {
                if (
                    this.isValidRideTo &&
                    this.isValidFromPoint &&
                    this.isValidDateTransportation &&
                    this.isValidDescription
                ) {
                    this.transportation_form = true;
                    // this.personal_details = true;
                    this.showError = false;

                    await this.handleSubmitMainData();


                } else {
                    this.showError = true;
                }
            } else if ( this.form_2 ) {
                if ( this.isValidexpiration_date && this.isValidDescription ) {
                    this.form_2 = true;
                    // this.personal_details = true;
                    this.showError = false;
                    await this.handleSubmitMainData();

                } else {
                    this.showError = true;
                }
            }


            // else if ( this.personal_details ) {
            //     if (
            //         this.isValid &&
            //         this.isValidA &&
            //         this.computedState != 'wrong-number' &&
            //         this.formFields.phone.length > 0
            //     ) {
            //
            //
            //
            //     } else {
            //         this.showError = true;
            //     }
            // }
            this.updateProgress();
        },
        async handleSubmitMainData() {
            //axios
            try {

                const selectedCategoryType = ( _.find(this.dropdowns.type_options, (i) => i.id == this.formFields.category_id) || {} ).type;

                const conversion_date = selectedCategoryType == 2 ? new Date(this.formFields.expiration_date) : new Date(this.formFields.arrival_date);
                const month = String(conversion_date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
                const day = String(conversion_date.getDate()).padStart(2, '0');
                const year = conversion_date.getFullYear();
                const date = `${ year }-${ month }-${ day }`;
                const time = conversion_date.toLocaleTimeString(this.locale, {
                    hour: '2-digit', minute: '2-digit', hour12: false
                });

                const response = await request({
                    method: 'post',
                    url: `/applications`,
                    data: {
                        ...this.formFields,
                        'expiration_date': selectedCategoryType == 2 ? date : null,
                        'arrival_date': selectedCategoryType == 1 ? date : null,
                        'arrival_time': selectedCategoryType == 1 ? time : null
                    }
                });

                this.formFields = { ...FORM_STATE };
                this.showToast();
                this.showError = false;
                this.transportation_form = false;
                ( this.form_2 = false ),
                    // ( this.personal_details = false );

                    this.progress1 = 0;
                this.progress2 = 0;
                // this.progress3 = 0;

                //refresh categories
                await this.getDropdowns();

            } catch (error) {
                if ( error.request && error.request.status && error.request.status === 422 ) {
                    this.formErrors = new Error(JSON.parse(error.request.responseText).errors);
                    if(this.formErrors.has('category_id')) {
                        this.notifyErrorWithMsg(this.formErrors.first('category_id'));
                    }
                    return false;
                } else {
                    this.notifyError();
                }
            }
        },
        async getDropdowns() {
            try {
                const response = await request({
                    method: 'post',
                    url: `/dropdowns/application`,
                    data: {
                        flag: 1
                    }
                });

                const { categories } = response;

                this.dropdowns.type_options = categories;
                // Initialize progress on load
                this.updateProgress();

            } catch (error) {
            }
        },
        showToast() {
           this.notifySuccessWithMsg(this.$t('title.helpRequestSuccessMessage'));
        },
    },
    mounted() {
        this.getDropdowns();
    },
    watch: {
        activeStep(newStep) {
            if ( newStep === 1 ) {
                this.progress1 = this.progress;
                this.progress2 = 0;
                // this.progress3 = 0;
            } else if ( newStep === 2 ) {
                this.progress1 = 100;
                this.progress2 = this.progress;
                // this.progress3 = 0;
            }
            // else if ( newStep === 3 ) {
            //     this.progress1 = 100;
            //     this.progress2 = 100;
            //     this.progress3 = this.progress;
            // }
        },
        progress(newProgress) {
            if ( this.activeStep === 1 ) {
                this.progress1 = newProgress;
            } else if ( this.activeStep === 2 ) {
                this.progress2 = newProgress;
            }
            // else if ( this.activeStep === 3 ) {
            //     this.progress3 = newProgress;
            // }
        },
    },
};
</script>
