import { request } from "@/Util/Request";

export default {
    data() {
        return {
            dropdowns: {
                addresses: {
                    arrival_address: [],
                    residential_address: [],
                    origin_address: [],
                    location: [],
                    address: []
                }
            },
        };
    },
    methods: {
        onFocus(key) {
            Object.keys(this.dropdowns.addresses).forEach(key => {
                this.dropdowns.addresses[key] = [];
            });
            if ( _.isEmpty(this.dropdowns.addresses[key]) ) {
                this.dropdowns.addresses[key] = [
                    {
                        id: "default",
                        label: this.$t('title.typeToSearch'),
                    }
                ];
            }
        },
        handleBlur(event) {
            setTimeout(() => {
                const element = event.target.parentElement;
                const children = Array.from(element.childNodes);

                children.forEach((child) => {
                    if (
                      child.nodeType === 1 &&
                      child.matches('ul.autocomplete-list') &&
                      child.children.length > 0
                    ) {
                        Array.from(child.children).forEach((li) => {
                            li.remove();
                        });
                    }
                });
            }, 500);
        },
        async search(key) {
            if ( key ) {
                try {
                    const response = await request({
                        method: 'post',
                        url: `dropdowns/places`,
                        data: {
                            text: this.formFields[key]
                        }
                    });

                    const { results } = response;

                    this.dropdowns.addresses[key] = _.map(results, (i) => {
                        const name = ( i.name ?? i.address_line1 ).split(",")[0].trim() ?? i.address_line1;
                        return {
                            id: `${ name }, ${ i.country }, ${ i.state }, ${ i.city ?? i.state }, ${ i.lat }, ${ i.lon }`,
                            label: `${ name }, ${ i.country }, ${ i.state }, ${ i.city ?? i.state }, ${ i.lat }, ${ i.lon }`
                        };
                    });

                } catch (error) {
                    return [];
                }
                return [];
            }
        },
        selectSuggestion(suggestion, key) {
            this.formFields[key] = suggestion.label;
            this.dropdowns.addresses[key] = [];
        },
    }
};
