<template>
    <b-card
        tag="article"
        class="mb-3 request-card"
        :class="{ 'clicked-parent': isClickedParent, urgent: item.status == 'completed' }"
        header-tag="header"
        footer-tag="footer"
    >
        <template #header>
            <h6 class="mb-0 d-flex align-items-center">
                <span class="icon"><b-icon icon="people" class="icon-style"></b-icon></span>
                {{ ( item.category || {} ).name }}
                <b-badge
                    v-if="item.is_volunteer_changed"
                    pill
                    variant="outline-warning"
                    class="gap-1 d-flex align-items-center fs-14 fw-normal ms-auto"
                >Transfer
                </b-badge
                >
                <!--                                <span class="rounded rounded-favourite ms-auto">-->
                <!--                          <b-icon :icon="ClickHandler" class="icon-style" @click="handleClick"></b-icon-->
                <!--                          ></span>-->
            </h6>
            <div class="d-flex justify-content-between">
                <b-badge
                    :variant="(item.status == 'open') ? 'warning' : (item.status == 'completed') ? 'success' : (item.status == 'in treatment') ? 'primary' : 'danger'"
                    class="mt-3">{{ $t(`title.${ _.camelCase(item.status) }`) }}
                </b-badge>
                <b-button v-if="item.status == 'in treatment'" @click="openCompleteRequestModal(item.id)"
                          :title="$t('title.complete')" v-b-tooltip.hover.right variant="outline-primary">
                    <b-icon icon="shield-check" class="icon-style"></b-icon>
                </b-button>
            </div>

        </template>
        <b-card-body class="p-0">
            <div class="address d-flex align-items-center gap-2 mb-3">
        <span class="rounded flex-shrink-0 h6 mb-0">
          <b-icon icon="geo" class="icon-style"></b-icon></span>
          <span class="three-lines" v-b-popover.hover.top="item.destination_address ?? ( ( item.user || {} ).residential_address )">{{ item.destination_address ?? ( ( item.user || {} ).residential_address ) }}</span>
            </div>
            <div class="user-name d-flex align-items-center gap-2 mb-3">
        <span class="rounded flex-shrink-0 h6 mb-0">
          <b-icon icon="person" class="icon-style"></b-icon></span
        >{{ ( item.user || {} ).full_name }}
            </div>
            <div class="disbility d-flex align-items-center gap-2 mb-3">
        <span class="rounded flex-shrink-0 h6 mb-0">
          <b-icon icon="patch-question" class="icon-style"></b-icon></span
        >{{ item.user?.disability?.name }}
            </div>

            <div class="phone d-flex align-items-center gap-2">
        <span class="rounded flex-shrink-0 h6 mb-0">
          <b-icon icon="phone" class="icon-style"></b-icon>
        </span>
                {{ ( item.user || {} ).phone }}
            </div>

            <div class="d-flex flex-wrap mt-3 col-10 p-0 gap-1">
                <b-badge pill variant="outline" class="gap-1 d-flex align-items-center fs-14 fw-normal"
                         v-if="item.user?.is_phone > 0"
                >{{ $t('title.phone') }}
                    <b-icon icon="phone" class="icon-style"></b-icon
                    >
                </b-badge>
                <b-badge pill variant="outline" class="gap-1 d-flex align-items-center fs-14 fw-normal"
                         v-if="item.user?.is_wp > 0"
                >{{ $t('title.whatsApp') }}
                    <b-icon icon="whatsapp" class="icon-style"></b-icon
                    >
                </b-badge>
            </div>
        </b-card-body>
        <b-collapse :id="`collapse-${index}`"
        >
            <b-card-body class="p-0 pt-3 mt-3">
                <p class="font-weight-bold mb-2">{{ $t('title.referenceDescription') }}</p>
                <p>
                    {{ item.description }}
                </p>
                <hr />
                <p class="font-weight-bold mb-2">{{ $t('title.comments') }}</p>
                <p>{{ ( item.user || {} ).comments }}</p>

                <hr />
                <div class="disbility d-flex align-items-center gap-2">
          <span class="rounded flex-shrink-0 h6 mb-0">
            <b-icon icon="calendar" class="icon-style"></b-icon></span
          >{{ $t('title.relevantUntil') }}: {{ item.expiration_date }}
                </div>
            </b-card-body>
        </b-collapse
        >
        <div class="d-flex flex-wrap justify-content-between mt-3 col-2 pe-0 positioned-button">
            <b-button v-b-toggle="`collapse-${index}`" class="rounded-collapse"
            >
                <b-icon :icon="ClickHandlerOpen" @click="handleClickOpen" class="icon-style"></b-icon
                >
            </b-button>
        </div>
        <template #footer v-if="!flag">
            <div class="d-flex flex-column" v-if="!item.application_requested_volunteer">
                <SuperBtn :text="$t('title.imReadyAccept')" class="fs-14" v-on:clickBtn="handleSubmit(item.id,0)" />
                <b-button :disabled="loader" variant="link" class="fs-14" @click="handleSubmit(item.id,2)"
                >{{ $t('title.sorryICantHelpThisTime') }}
                </b-button>
            </div>
            <div class="d-flex flex-column"
                 v-if="item.application_requested_volunteer && item.application_requested_volunteer.is_approved != 2">
                <b-button type="button" :disabled="true" variant="danger" class="fs-14">
                    {{ $t('title.waitingForApproval') }}
                </b-button>
            </div>
        </template>
        <VolunteerRequestCompleteModal ref="requestCompleteModal" @refreshCard="refreshCard" />
    </b-card>
</template>
<script>
import { BCard, BButton, BCollapse, BBadge } from 'bootstrap-vue';
import SuperBtn from './SuperBtn.vue';
import { request } from "@/Util/Request";
import { mapState } from "vuex";
import VolunteerRequestCompleteModal from "@/view/pages/volunteer/requestBasekt/modal/VolunteerRequestCompleteModal";
import Error from "@/Util/Error";

export default {
    components: {
        BCard,
        BButton,
        BBadge,
        BCollapse,
        SuperBtn,
        VolunteerRequestCompleteModal
    },
    props: {
        index: {
            type: Number,
            required: true,
        },
        selected: {
            type: Boolean,
            required: false,
        },
        item: {
            type: Object,
            required: true
        },
        userId: {
            type: [Number],
            required: true
        },
        flag: {
            type: Boolean,
            required: false
        }
    },
    data() {
        return {
            isClicked: this.selected ? true : false,
            isClickedOpen: false,
            isClickedParent: this.selected ? true : false,
            clickTimeout: null,
            formErrors: new Error({}),
        };
    },
    computed: {
        ClickHandler() {
            return this.isClicked ? 'star-fill' : 'star';
        },
        ClickHandlerOpen() {
            return this.isClickedOpen ? 'arrow-up-circle' : 'arrow-down-circle';
        },
        ...mapState(['user', 'loader'])
    },
    methods: {
        refreshCard() {
            this.$emit('refresh');
        },
        openCompleteRequestModal(requestId) {
            this.$refs['requestCompleteModal'].handleToggleModal(requestId);
        },
        handleClick() {
            this.isClickedParent = !this.isClickedParent;
            this.isClicked = !this.isClicked;
        },
        handleClickOpen() {
            this.isClickedOpen = !this.isClickedOpen;
        },
        async handleSubmit(id, status) {
            try {
                const response = await request({
                    method: 'post',
                    url: `/volunteer/application-users`,
                    data: {
                        application_id: id,
                        is_approved: status,
                        applied_volunteer_id: this.userId
                    }
                });

                if ( status == 0 ) {
                    const self = this;

                     this.notifySuccessWithMsg(this.$t('title.userHelpRequestAccepted'));
                } else {
                    this.showToast();
                }

                this.$emit('refresh');

            } catch (error) {
                if ( error.request && error.request.status && error.request.status === 422 ) {
                    this.formErrors = new Error(JSON.parse(error.request.responseText).errors);
                    if ( this.formErrors.has('applied_volunteer_id') ) {
                        this.notifyErrorWithMsg(this.formErrors.first('applied_volunteer_id'));
                    }

                    if ( this.formErrors.has('error') ) {
                        this.notifyErrorWithMsg(this.formErrors.first('error'));
                    }
                    return false;
                } else {
                    this.notifyError();
                }
            }
        },

        showToast() {
             this.notifySuccessWithMsg(this.$t('title.youDeclineRequest'));
        },
    },
};
</script>
