<template>
    <div class="side-list bg-blur">
        <div class="list-group-item d-flex justify-content-between gap-2 ">
            <b-button variant="primary" class="col-12 col-md-auto sec-btn small-btn w-100"  v-b-modal.modal-info-volunteer>
                {{ $t('title.info') }}
            </b-button>
        </div>
        <div class="overflow-auto">
            <div class="menu mt-1">
                <ul class="px-0 m-0">
                    <li v-for="volunteer in volunteers" :key="volunteer.index">
                        <b-list-group-item
                            class="list-group-item d-flex justify-content-between gap-2 flex-row align-items-start align-items-md-center"
                        >
                            <div class="d-flex align-items-center">
                                <b-dropdown
                                    :id="'volunteer-management-dropdown-status' + volunteer.id"
                                    text=""
                                    class=""
                                    right
                                >
                                    <template #button-content>
                                        <b-avatar class="mr-2" :size="30" badge badge-variant="primary"
                                                  v-if="volunteer.status == 'pending'">
                                        </b-avatar>
                                    </template>
                                    <b-dropdown-item @click="handleAcceptRejectModal(volunteer.id, 'accept')">{{ $t('title.accept') }}</b-dropdown-item>
                                    <b-dropdown-item @click="handleAcceptRejectModal(volunteer.id, 'reject')">{{ $t('title.reject') }}</b-dropdown-item>
                                </b-dropdown>
                                <b-avatar class="mr-2" :size="30" badge badge-variant="success"
                                          v-if="volunteer.status == 'accept'"></b-avatar>
                                <b-dropdown
                                    :id="'volunteer-action-dropdown-status' + volunteer.id"
                                    text=""
                                    class=""
                                    right
                                    v-if="volunteer?.assigned_user?.is_approved == 1 && volunteer.status == 'assigned'"
                                >
                                    <template #button-content>
                                        <b-avatar class="mr-2" :size="30" badge badge-variant="warning"
                                          v-if="volunteer.status == 'assigned'"></b-avatar>
                                    </template>
                                    <b-dropdown-item @click="handleCompleteRejectModal(volunteer, 'completed')">{{ $t('title.complete') }}</b-dropdown-item>
                                    <b-dropdown-item @click="handleCompleteRejectModal(volunteer, 'reject')">{{ $t('title.reject') }}</b-dropdown-item>
                                </b-dropdown>
                                <div v-else>
                                    <b-avatar class="mr-2" :size="30" badge badge-variant="warning"
                                          v-if="volunteer.status == 'assigned'"></b-avatar>
                                </div>
                                <b-avatar class="mr-2" :size="30" badge badge-variant="danger"
                                          v-if="volunteer.status == 'reject'"></b-avatar>
                                <b-avatar class="mr-2" :size="30" badge badge-variant="info"
                                          v-if="volunteer.status == 'completed'"></b-avatar>
                                <span class="me-auto"><a href="#" @click.prevent="openVolunteerDetailModal(volunteer)">{{ ( volunteer.volunteer || {} ).full_name }}</a></span>
                            </div>
                            <div class="button-group ms-md-auto d-flex gap-1 align-items-center">
                                <UserDropdown v-if="volunteer.status == 'accept' || volunteer.status == 'assigned'" :eventId="eventId"  :volunteerRequestDetail="volunteer" @open-modal="openModal" />
                            </div>
                        </b-list-group-item>
                    </li>
                </ul>
            </div>
        </div>

        <UserAssignWithDetailModal ref="userDetailModal" @assignUser="handleAssignUser" />
        <VolunteerRequestAcceptRejectModal ref="volunteerAcceptRejectModal" @refreshVolunteerList="handleRefreshVolunteerList"/>
        <VolunteerDetailModel ref="volunteerDetailModal"/>
        <VolunteerInfoModal ref="volunteerInfoModal"/>
        <VolunteerCompleteRejectRequestModal ref="volunteerCompleteRejectRequest" @refreshVolunteerList="handleRefreshVolunteerList"/>
    </div>
</template>

<script>
import UserDropdown from './UserDropdown.vue';
import {request} from "@/Util/Request";
import VolunteerRequestAcceptRejectModal from "@/view/pages/manager/events/modal/VolunteerRequestAcceptRejectModal";
import UserAssignWithDetailModal from "@/view/pages/manager/events/modal/UserAssignWithDetailModal";
import VolunteerDetailModel from "@/view/pages/manager/events/modal/VolunteerDetailModel";
import VolunteerInfoModal from "@/view/pages/manager/events/modal/VolunteerInfoModal"
import VolunteerCompleteRejectRequestModal from "@/view/pages/manager/events/modal/VolunteerCompleteRejectRequestModal";
import Error from "@/Util/Error";
export default {
    props: ['volunteers', 'eventId'],
    components: {
        UserDropdown,
        VolunteerRequestAcceptRejectModal,
        UserAssignWithDetailModal,
        VolunteerDetailModel,
        VolunteerInfoModal,
        VolunteerCompleteRejectRequestModal
    },
    data() {
        return {
            formErrors: new Error({}),
        };
    },
    methods: {
        openVolunteerDetailModal(volunteer) {
            this.$refs['volunteerDetailModal'].handleToggleModal(volunteer);
        },
        openModal({user, volunteerRequestId}) {
            this.$refs['userDetailModal'].handleToggleModal(user, volunteerRequestId);
        },
        async handleAssignUser({userRequestId, volunteerRequestId}) {
            try {
                const response = await request({
                    method: 'put',
                    url: `/event-requests/user/${volunteerRequestId }`,
                    data: {
                        'user_request_id': userRequestId
                    }
                });
                if(response) {
                    this.notifySuccessWithMsg(`${ this.$t('title.user') } ${ this.$t('title.assignSuccessMessage') }`);
                    this.handleRefreshVolunteerList();
                    this.formErrors = new Error({});
                }
            } catch (error) {
                if ( error.request && error.request.status && error.request.status === 422 ) {
                    this.formErrors = new Error(JSON.parse(error.request.responseText).errors);
                    if(this.formErrors.has('message')) {
                        this.notifyErrorWithMsg(this.formErrors.first('message'));
                    }

                    if(this.formErrors.has('error')) {
                        this.notifyErrorWithMsg(this.formErrors.first('error'));
                    }
                    return false;
                } else {
                    this.notifyError();
                }
            }
        },
        changeBadgeColor(row, color) {
            row.badge = color; // Dynamically update the badge color
        },
        handleAcceptRejectModal(id, status) {
            this.$refs['volunteerAcceptRejectModal'].handleToggleModal(id, status);
        },
        handleCompleteRejectModal(volunteer, status) {
            this.$refs['volunteerCompleteRejectRequest'].handleToggleModal(volunteer.assigned_user.id, status);
        },
        handleRefreshVolunteerList() {
           this.$emit('refreshVolunteers');
        }
    },
    watch: {
        volunteers: {
            handler(newVal, oldVal) {
                // console.log('Items updated:', newVal);
            },
            deep: true, // Use this to watch for changes within objects/arrays
        },
    },
};
</script>
